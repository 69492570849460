import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Typography,
  Alert,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  styled,
  Snackbar,
  createTheme,
  ThemeProvider
} from '@mui/material';
import { Edit, Save, Cancel } from '@mui/icons-material';

// Custom theme with brand colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#004c97',
      light: '#3370ab',
      dark: '#003569',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff6c0e',
      light: '#ff8c3e',
      dark: '#b24b09',
      contrastText: '#ffffff',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(3),
  fontSize: '1rem',
  '&.MuiTableCell-head': {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .MuiTableCell-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&.MuiButton-outlinedError': {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.main,
    },
  },
}));

const formatDateForAPI = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingProject, setEditingProject] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editedValues, setEditedValues] = useState({
    name: '',
    start_date: '',
    end_date: '',
    status: ''
  });

  const fetchProjects = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get('http://127.0.0.1:8000/getprojects', {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      setProjects(response.data);
    } catch (err) {
      setError('Failed to fetch projects. Please try again later.');
      console.error('Error fetching projects:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleEditClick = (project) => {
    setEditingProject(project);
    setEditedValues({
      name: project.name || '',
      start_date: project.start_date || '',
      end_date: project.end_date || '',
      status: project.status || ''
    });
    setDialogOpen(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setError(null);

      const updateData = {
        id: editingProject.id,
        ...Object.fromEntries(
          Object.entries(editedValues).filter(([_, value]) => value !== null && value !== '')
        )
      };

      console.log('Sending update data:', updateData);

      const response = await axios.patch(
        'http://127.0.0.1:8000/updateproject',
        updateData,{
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });

      if (response.data.message) {
        await fetchProjects();
        setDialogOpen(false);
        setEditingProject(null);
        setSnackbarOpen(true); 
      }
    } catch (err) {
      console.error('Error updating project:', err.response?.data || err.message);
      setError(err.response?.data?.detail || 'Failed to update project. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setEditingProject(null);
    setEditedValues({
      name: '',
      start_date: '',
      end_date: '',
      status: ''
    });
    setError(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const getStatusColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'planning':
        return 'info.light';
      case 'in progress':
        return 'warning.light';
      case 'completed':
        return 'success.light';
      case 'on hold':
        return 'error.light';
      default:
        return 'grey.300';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 4, maxWidth: '1600px', mx: 'auto', mt:-8 }}>
        <Card elevation={3}>
          <CardContent sx={{ p: 4 }}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert 
                onClose={handleSnackbarClose} 
                severity="success" 
                sx={{ 
                  width: '100%',
                  bgcolor: 'secondary.main'
                }}
                elevation={6}
                variant="filled"
              >
                Project updated successfully!
              </Alert>
            </Snackbar>

            <Typography 
              variant="h4" 
              sx={{ 
                mb: 4, 
                fontWeight: 600, 
                color: 'primary.main',
                borderBottom: '3px solid',
                borderColor: 'secondary.main',
                pb: 1
              }}
            >
              Project Management
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 4 }}>
                {error}
              </Alert>
            )}

            <TableContainer component={Paper} sx={{ mb: 4, boxShadow: 3 }}>
              <Table sx={{ minWidth: 1200 }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: 'primary.main' }}>
                    <StyledTableCell>Project Name</StyledTableCell>
                    <StyledTableCell>Start Date</StyledTableCell>
                    <StyledTableCell>End Date</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects.map((project) => (
                    <StyledTableRow key={project.id}>
                      <StyledTableCell sx={{ fontWeight: 500, color: 'primary.main' }}>
                        {project.name}
                      </StyledTableCell>
                      <StyledTableCell>{project.start_date}</StyledTableCell>
                      <StyledTableCell>{project.end_date}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            backgroundColor: getStatusColor(project.status),
                            px: 2,
                            py: 1,
                            borderRadius: 1,
                            display: 'inline-block',
                            color: 'white'
                          }}
                        >
                          {project.status}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <StyledButton
                          startIcon={<Edit />}
                          onClick={() => handleEditClick(project)}
                          variant="contained"
                          color="primary"
                          size="large"
                          sx={{ px: 3 }}
                        >
                          Edit
                        </StyledButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Dialog 
              open={dialogOpen} 
              onClose={handleClose} 
              maxWidth="md" 
              fullWidth
              PaperProps={{
                sx: { p: 2 }
              }}
            >
              <DialogTitle 
                sx={{ 
                  fontSize: '1.5rem', 
                  fontWeight: 600,
                  color: 'primary.main'
                }}
              >
                Edit Project
              </DialogTitle>
              <DialogContent>
                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <TextField
                    label="Project Name"
                    value={editedValues.name}
                    onChange={(e) => setEditedValues({ ...editedValues, name: e.target.value })}
                    fullWidth
                    size="large"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'primary.main',
                      },
                    }}
                  />
                  <TextField
                    label="Start Date"
                    type="date"
                    value={editedValues.start_date}
                    onChange={(e) => setEditedValues({ 
                      ...editedValues, 
                      start_date: formatDateForAPI(e.target.value)
                    })}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="large"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'primary.main',
                      },
                    }}
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    value={editedValues.end_date}
                    onChange={(e) => setEditedValues({ 
                      ...editedValues, 
                      end_date: formatDateForAPI(e.target.value)
                    })}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="large"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'primary.main',
                      },
                    }}
                  />
                  <FormControl fullWidth size="large">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={editedValues.status}
                      onChange={(e) => setEditedValues({ ...editedValues, status: e.target.value })}
                      label="Status"
                      sx={{
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'primary.main',
                        },
                      }}
                    >
                      <MenuItem value="Planning">Planning</MenuItem>
                      <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                      <MenuItem value="ON_HOLD">On Hold</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <StyledButton 
                  onClick={handleClose}
                  startIcon={<Cancel />}
                  color="error"
                  variant="outlined"
                  size="large"
                  sx={{ px: 4 }}
                >
                  Cancel
                </StyledButton>
                <StyledButton 
                  onClick={handleSave}
                  startIcon={<Save />}
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  sx={{ px: 4 }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save'}
                </StyledButton>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default ProjectList;