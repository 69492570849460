import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Alert,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Stack,
  Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { format, eachDayOfInterval, isSunday } from 'date-fns';

const LeaveRequestForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [formData, setFormData] = useState({
    employee_id: 1,
    leave_type: '',
    leave_start_date: null,
    leave_end_date: null,
    reason: '',
    selectedDays: [],
    status: 6
  });

  const handleDateSelection = (field, value) => {
    setFormData(prev => {
      const newData = { ...prev, [field]: value };
      
      if (newData.leave_start_date && newData.leave_end_date) {
        const days = eachDayOfInterval({
          start: newData.leave_start_date,
          end: newData.leave_end_date
        });
        
        const workingDays = days
          .filter(day => !isSunday(day))
          .map(day => ({
            date: day,
            dayName: format(day, 'EEEE'),
            dateStr: format(day, 'dd/MM/yy'),
            duration: 1,
            leaveType: prev.leave_type
          }));
          
        newData.selectedDays = workingDays;
      }
      
      return newData;
    });
  };

  const toggleDuration = (dateStr) => {
    setFormData(prev => ({
      ...prev,
      selectedDays: prev.selectedDays.map(day => 
        day.dateStr === dateStr 
          ? { ...day, duration: day.duration === 1 ? 0.5 : 1 }
          : day
      )
    }));
  };

  const updateDayLeaveType = (dateStr, leaveType) => {
    setFormData(prev => ({
      ...prev,
      selectedDays: prev.selectedDays.map(day => 
        day.dateStr === dateStr 
          ? { ...day, leaveType }
          : day
      )
    }));
  };

  const removeDay = (dateStr) => {
    setFormData(prev => ({
      ...prev,
      selectedDays: prev.selectedDays.filter(day => day.dateStr !== dateStr)
    }));
  };

  const calculateTotalLeaveDays = () => {
    return formData.selectedDays.reduce((total, day) => total + day.duration, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const requests = formData.selectedDays.map(async (day) => {
        const requestData = {
          employee_id: formData.employee_id,
          leave_type: day.leaveType,
          leave_start_date: format(day.date, 'yyyy-MM-dd'),
          leave_end_date: format(day.date, 'yyyy-MM-dd'),
          duration: day.duration,
          status: formData.status,
          reason: formData.reason,
          submission_date: format(new Date(), 'yyyy-MM-dd')
        };

        try {
          const response = await axios.post('http://127.0.0.1:8000/leave-request', requestData,{
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          });
          return response.data;
        } catch (err) {
          throw new Error(err.response?.data?.detail || 'Failed to submit leave request');
        }
      });

      await Promise.all(requests);
      
      setSuccess('Leave requests submitted successfully!');
      
      // Reset form
      setFormData({
        employee_id: 1,
        leave_type: '',
        leave_start_date: null,
        leave_end_date: null,
        reason: '',
        selectedDays: [],
        status: 6
      });
    } catch (err) {
      setError(err.message || 'Failed to submit leave requests');
    } finally {
      setLoading(false);
    }
  };

  const handleLeaveTypeChange = (e) => {
    const newLeaveType = e.target.value;
    setFormData(prev => ({
      ...prev,
      leave_type: newLeaveType,
      selectedDays: prev.selectedDays.map(day => ({
        ...day,
        leaveType: newLeaveType
      }))
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Submit Leave Request
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}
          
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Leave Type</InputLabel>
              <Select
                value={formData.leave_type}
                onChange={handleLeaveTypeChange}
                label="Leave Type"
              >
                <MenuItem value="Annual">Annual Leave</MenuItem>
                <MenuItem value="Sick">Sick Leave</MenuItem>
                <MenuItem value="Personal">Personal Leave</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <DatePicker
                label="Start Date"
                value={formData.leave_start_date}
                onChange={(value) => handleDateSelection('leave_start_date', value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true,
                    margin: "normal"
                  }
                }}
                minDate={new Date()}
              />

              <DatePicker
                label="End Date"
                value={formData.leave_end_date}
                onChange={(value) => handleDateSelection('leave_end_date', value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: true,
                    margin: "normal"
                  }
                }}
                minDate={formData.leave_start_date || new Date()}
              />
            </Stack>

            {formData.selectedDays.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Selected Days: (Total: {calculateTotalLeaveDays()} days)
                </Typography>
                <Paper variant="outlined" sx={{ mt: 1 }}>
                  <List disablePadding>
                    {formData.selectedDays.map((day, index) => (
                      <React.Fragment key={day.dateStr}>
                        {index > 0 && <Divider />}
                        <ListItem>
                          <ListItemText 
                            primary={
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography>{`${day.dateStr} - ${day.dayName}`}</Typography>
                                <Select
                                  value={day.duration}
                                  onChange={() => toggleDuration(day.dateStr)}
                                  variant="standard"
                                  size="small"
                                  sx={{ minWidth: 80 }}
                                >
                                  <MenuItem value={1}>Full Day</MenuItem>
                                  <MenuItem value={0.5}>Half Day</MenuItem>
                                </Select>
                                <FormControl size="small" sx={{ minWidth: 120 }}>
                                  <Select
                                    value={day.leaveType}
                                    onChange={(e) => updateDayLeaveType(day.dateStr, e.target.value)}
                                    variant="standard"
                                  >
                                    <MenuItem value="Annual">Annual</MenuItem>
                                    <MenuItem value="Sick">Sick</MenuItem>
                                    <MenuItem value="Personal">Personal</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Stack>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() => removeDay(day.dateStr)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              </Box>
            )}

            <TextField
              fullWidth
              label="Reason"
              multiline
              rows={4}
              value={formData.reason}
              onChange={(e) => setFormData(prev => ({ ...prev, reason: e.target.value }))}
              margin="normal"
              required
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
              disabled={loading || formData.selectedDays.length === 0}
            >
              {loading ? 'Submitting...' : 'Submit Leave Request'}
            </Button>
          </Box>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default LeaveRequestForm;