// utils/axiosConfig.js
import axios from 'axios';

export const api = axios.create({
  baseURL: 'http://127.0.0.1:8000',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Add request interceptor to handle cookies
api.interceptors.request.use(
  (config) => {
    // Get cookies from document.cookie if needed
    const cookies = document.cookie;
    // console.log(cookies,"cookie");
    // console.log(config,"fohoogogogog");
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    // console.log(response.headers,"responseeeeeeee");
    
    // Handle the new access token if it's in the response
    if (response.headers['set-cookie']) {
      // Cookie will be automatically handled by the browser
      // console.log('New token received');
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // Redirect to login page or handle refresh
      // console.log('Authentication error');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);