import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Alert,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Card,
  CardContent
} from '@mui/material';
import {
  Person,
  CalendarMonth,
  Search,
  Warning,
  CheckCircle,
  Lock as LockIcon,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from 'context/AuthContext'; 

// Custom theme with company colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#004c97',
      light: '#3370ab',
      dark: '#003569',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ff6c0e',
      light: '#ff8c3e',
      dark: '#b24b09',
      contrastText: '#fff'
    }
  }
});

const EmployeeRelieving = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [showPassword, setShowPassword] = useState(false);
  
  const [passwordDialog, setPasswordDialog] = useState({
    open: false,
    password: '',
    employeeId: null,
    employeeName: ''
  });

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      setLoading(true);
      const response = await axios.get('http://127.0.0.1:8000/fetch-employeeprofile',{
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      setProfiles(response.data);
    } catch (error) {
      showSnackbar(error.response?.data?.detail || 'Failed to fetch profiles', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleRelieveConfirm = (userId, name) => {
    if (!selectedDate) {
      showSnackbar('Please select a relieving date first', 'warning');
      return;
    }
    setPasswordDialog({
      open: true,
      password: '',
      employeeId: userId,
      employeeName: name
    });
  };

  const handlePasswordVerification = async () => {
    try {
      setLoading(true);
      const response = await axios.patch('http://127.0.0.1:8000/relieve-date', {
        id: passwordDialog.employeeId,
        user_id: user.user_id, // Current user's ID
        user_password: passwordDialog.password,
        relieve_date: selectedDate
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      
      showSnackbar('Employee relieved successfully');
      fetchProfiles();
      setPasswordDialog({ ...passwordDialog, open: false });
    } catch (error) {
      showSnackbar(error.response?.data?.detail || 'Failed to relieve employee', 'error');
    } finally {
      setLoading(false);
    }
  };

  const filteredProfiles = profiles.filter(profile => 
    profile.employee_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    profile.last_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 4, mt: -14 }}>
        <Container maxWidth="lg">
          <Card elevation={5} sx={{ mb: 4, borderRadius: 2, overflow: 'visible' }}>
            <CardContent sx={{ p: 4 }}>
              <Typography 
                variant="h5" 
                gutterBottom 
                sx={{ 
                  mb: 4, 
                  color: 'primary.main',
                  fontWeight: 'bold'
                }}
              >
                Employee Relieving Management
              </Typography>
              
              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 2,
                      bgcolor: '#f8f9fa'
                    }}
                  >
                    <IconButton sx={{ p: '10px', color: 'primary.main' }}>
                      <Search />
                    </IconButton>
                    <TextField
                      fullWidth
                      placeholder="Search employee by Employee Code"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Relieving Date"
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        bgcolor: '#f8f9fa'
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonth sx={{ color: 'primary.main' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <TableContainer component={Paper} elevation={3} sx={{ borderRadius: 2 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ bgcolor: 'primary.main' }}>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Employee Name</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Employee Code</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Role</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Joining Date</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Email</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
                 
                 
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center" sx={{ py: 4 }}>
                      <CircularProgress sx={{ color: 'secondary.main' }} />
                    </TableCell>
                  </TableRow>
                ) : filteredProfiles.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center" sx={{ py: 4 }}>
                      <Typography color="textSecondary">
                        No employees found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredProfiles.map((profile) => (
                    <TableRow 
                      key={profile.id}
                      hover
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { bgcolor: 'primary.light + 10' }
                      }}
                    >
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Person sx={{ color: 'primary.main' }} />
                          {profile.first_name} {profile.last_name}
                        </Box>
                      </TableCell>
                      <TableCell>
                      <TableCell>{profile.employee_id}</TableCell>
                      </TableCell>
                      <TableCell>{profile.role}</TableCell>
                     <TableCell> {new Date(profile.joining_date).toLocaleDateString()} </TableCell>
                      <TableCell>{profile.office_email}</TableCell>
                      <TableCell>
                        <Chip
                          label={profile.relieving_date ? 'Relieved' : 'Active'}
                          color={profile.relieving_date ? 'error' : 'success'}
                          size="small"
                          sx={{
                            bgcolor: profile.relieving_date ? '#ffebee' : 'success',
                            color: profile.relieving_date ? '#d32f2f' : 'success',
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleRelieveConfirm(
                            profile.id, 
                            `${profile.first_name} ${profile.last_name}`
                          )}
                          disabled={profile.relieving_date}
                          color="secondary"
                          sx={{ borderRadius: 2 }}
                        >
                          Relieve
                        </Button>
                      </TableCell>
                      
                      
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Password Verification Dialog */}
          <Dialog
            open={passwordDialog.open}
            onClose={() => setPasswordDialog({ ...passwordDialog, open: false })}
            PaperProps={{
              sx: { 
                borderRadius: 2,
                borderTop: '4px solid',
                borderColor: 'primary.main'
              }
            }}
          >
            <DialogTitle sx={{ 
              color: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <LockIcon /> Password Verification Required
            </DialogTitle>
            <DialogContent sx={{ mt: 2, minWidth: 400 }}>
              <Typography gutterBottom>
                Please enter your password to confirm relieving <strong>{passwordDialog.employeeName}</strong>
              </Typography>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Password"
                value={passwordDialog.password}
                onChange={(e) => setPasswordDialog({ ...passwordDialog, password: e.target.value })}
                margin="normal"
                variant="outlined"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'primary.main',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'primary.main',
                  },
                }}
              />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Relieving Date: {selectedDate}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button 
                onClick={() => setPasswordDialog({ ...passwordDialog, open: false })}
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
              <Button 
                onClick={handlePasswordVerification}
                variant="contained"
                color="secondary"
                disabled={!passwordDialog.password || loading}
                startIcon={loading ? <CircularProgress size={20} /> : <CheckCircle />}
              >
                Verify & Proceed
              </Button>
            </DialogActions>
          </Dialog>

          {/* Snackbar for notifications */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert 
              onClose={() => setSnackbarOpen(false)} 
              severity={snackbarSeverity}
              sx={{ 
                width: '100%',
                ...(snackbarSeverity === 'success' && {
                  bgcolor: 'secondary.light',
                  color: 'secondary.dark',
                  '& .MuiAlert-icon': {
                    color: 'secondary.dark'
                  }
                })
              }}
              elevation={6}
              variant="filled"
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default EmployeeRelieving;