import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PushSpinner } from "react-spinners-kit";
import { useDispatch } from "react-redux";
import kefi from '../images/kefi.png'
import './Login.css';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useAuth } from '../context/AuthContext';

const RECAPTCHA_SITE_KEY = '6Lf0jpkqAAAAAOVKb1j7hsMeqU-rLm_D2gQ7OlmF';

const Login = () => {
  const [quotes, setQuotes] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { login, loading, error } = useAuth();
  const [Loading,setLoading]=useState(false)

  useEffect(() => {
    fetchQuotes();
    const container = containerRef.current;
    setTimeout(() => {
      container.classList.add('loginContainer--signIn');
    }, 200);
  }, []);

  const fetchQuotes = async () => {
    try {
      const response = await axios.get("http://127.0.0.1:8000/fetch-quote");
      setQuotes(response.data);
    } catch (error) {
      console.error("Failed to fetch quotes:", error);
    }
  };

  const toggle = () => {
    const container = containerRef.current;
    container.classList.toggle('loginContainer--signIn');
    container.classList.toggle('loginContainer--signUp');
  };

  const onChange = (value) => {
    // console.log("Captcha value:", value);
    setIsCaptchaVerified(!!value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isCaptchaVerified) {
      setMessage('Please complete the captcha');
      return;
    }

    try {
      setLoading(true)
      const success = await login(email, password);

      if (success) {
        setTimeout(() => {
          navigate('/home');
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);
        setMessage(error || 'Login failed');
      }
    } catch (error) {
      setLoading(false);
      setMessage('An error occurred during login');
    }
  };

  return (
    <>
    
    <div ref={containerRef} className="loginContainer">
      <form id="form-1" className="sioii"  onSubmit={handleSubmit}>
        <div className="loginRow">
          <div className="loginCol loginCol--alignCenter loginCol--flexCol loginCol--signUp">
            <div className="loginFormWrapper loginCol--alignCenter">
              <div className="loginForm loginForm--signUp">
                <div className="loginInputGroup">
                  <i className='bx bxs-user'></i>
                  <input type="text" placeholder="Username" className="loginInput" />
                </div>
                <div className="loginInputGroup">
                  <i className='bx bx-mail-send'></i>
                  <input type="email" placeholder="Email" className="loginInput" />
                </div>
                <div className="loginInputGroup">
                  <i className='bx bxs-lock-alt'></i>
                  <input type="password" placeholder="Password" className="loginInput" />
                </div>
                <div className="loginInputGroup">
                  <i className='bx bxs-lock-alt'></i>
                  <input type="password" placeholder="Confirm password" className="loginInput" />
                </div>
                <button className="loginButton">Sign up</button>
                <p className="loginText">
                  <span>Already have an account?</span>
                  <b onClick={toggle} className="loginPointer">Sign in here</b>
                </p>
              </div>
            </div>
          </div>
            
          <div className="loginCol loginCol--alignCenter loginCol--flexCol loginCol--signIn">
          <img className='kefiimg' src={kefi} alt="kefiLogo" />
            <div className="loginFormWrapper loginCol--alignCenter">
              
              <div className="loginForm loginForm--signIn">
                <h1 className='links'>LYNKS</h1>
                <div className="loginInputGroup">
                  <i className='bx bxs-user'></i>
                  <input
                    type="text"
                    placeholder="Username"
                    className="loginInput"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="loginInputGroup">
                  <i className='bx bxs-lock-alt'></i>
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="loginInput"
                    required
                  />
                </div>
                <button 
                  type="submit" 
                  disabled={Loading || !isCaptchaVerified}
                  className="loginButton"
                >
                  {Loading ? (
                    <center><PushSpinner size={30} color="LightYellow" /></center>
                  ) : (
                    'Login'
                  )}
                </button>
                <p></p>
                <ReCAPTCHA
                  sitekey="6Lf0jpkqAAAAAOVKb1j7hsMeqU-rLm_D2gQ7OlmF"
                  onChange={onChange}
                />

                <h1 className="loginMessage">{message && <p>{message}</p>}</h1>
                <p className="loginText"></p>
                <p className="loginText"></p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="loginContentRow">
          <div className="loginCol loginCol--alignCenter loginCol--flexCol">
            <div className="loginWelcomeText loginWelcomeText--signIn">
              <h2>{quotes.content}</h2>
            </div>
          </div>
          <div className="loginCol loginCol--alignCenter loginCol--flexCol">
            <div className="loginWelcomeText loginWelcomeText--signUp">
            </div>
          </div>
        </div>
      </form>
    </div>
    </>
  );
};

export default Login;