import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  Snackbar,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Switch,
  Alert
} from '@mui/material';
import {
  Person,
  Email,
  Badge,
  CalendarMonth,
  Key,
  ContentCopy,
  ArrowForward,
  Timeline,
  AttachMoney,
  DarkMode,
  LightMode,
  SupervisorAccount
} from '@mui/icons-material';
import axios from 'axios';

const EmployeeLogin = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    leave: '',
    salary: '',
    office_email: '',
    employee_id: '',
    joining_date: '',
    probation_start_date: '',
    probation_end_date: '',
    password: '',
    role_id: ''
  });
  
  const [isProbation, setIsProbation] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const response = await axios.get('http://127.0.0.1:8000/fetch-roles',{
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
      showSnackbar(error.response?.data?.detail || 'Failed to fetch roles', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRoleChange = (event) => {
    setFormData(prev => ({
      ...prev,
      role_id: event.target.value
    }));
  };

  const handleProbationChange = (e) => {
    setIsProbation(e.target.checked);
    if (!e.target.checked) {
      setFormData(prev => ({
        ...prev,
        probation_start_date: '',
        probation_end_date: ''
      }));
    }
  };

  const generatePassword = () => {
    const charset = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let newPassword = '';
    const passwordLength = 8;

    for (let i = 0; i < passwordLength; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setFormData((prev) => ({
      ...prev,
      password: newPassword
    }));
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(formData.password);
      showSnackbar('Password copied to clipboard!');
    } catch (err) {
      showSnackbar('Failed to copy password', 'error');
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const resetForm = ()=>{
    setFormData(
      {
        first_name: '',
        last_name: '',
        leave: '',
        salary: '',
        office_email: '',
        employee_id: '',
        joining_date: '',
        probation_start_date: '',
        probation_end_date: '',
        password: '',
        role_id: ''
      }
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.role_id) {
      showSnackbar('Please select a role', 'error');
      return;
    }

    const submitData = {
      ...formData,
      probation_start_date: formData.probation_start_date || null,
      probation_end_date: formData.probation_end_date || null
    };
    
    try {
      setLoading(true);
      const response = await axios.post('http://127.0.0.1:8000/employee-create', submitData);
      resetForm()
      showSnackbar('Employee details saved successfully!');
    } catch (error) {
      console.error('Submission error:', error);
      showSnackbar(error.response?.data?.detail || 'Failed to save employee details', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md">
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4,
            borderRadius: 2,
            mt: -9
          }}
        >
          <Typography 
            variant="h4" 
            component="h1" 
            align="center" 
            gutterBottom
            sx={{ mb: 4, color: 'primary.main' }}
          >
            Employee Login Details
          </Typography>

          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="first_name"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={formData.first_name}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="last_name"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  value={formData.last_name}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="leave"
                  label="Leave Days"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={formData.leave}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Timeline />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="salary"
                  label="Salary"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={formData.salary}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoney />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="office_email"
                  label="Office Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  value={formData.office_email}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="employee_id"
                  label="Employee ID"
                  variant="outlined"
                  fullWidth
                  value={formData.employee_id}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Badge />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="joining_date"
                  label="Joining Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={formData.joining_date}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonth />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Role</InputLabel>
                  <Select
                    name="role_id"
                    value={formData.role_id}
                    onChange={handleRoleChange}
                    label="Select Role"
                    disabled={loading}
                    
                    startAdornment={
                      <InputAdornment position="start">
                        <SupervisorAccount />
                      </InputAdornment>
                    }
                  >  

                    {roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isProbation}
                      onChange={handleProbationChange}
                      color="primary"
                    />
                  }
                  label="Employee on Probation"
                  sx={{ color: 'text.primary' }}
                />
              </Grid>

              {isProbation && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="probation_start_date"
                      label="Probation Start Date"
                      type="date"
                      variant="outlined"
                      fullWidth
                      value={formData.probation_start_date}
                      onChange={handleInputChange}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarMonth />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="probation_end_date"
                      label="Probation End Date"
                      type="date"
                      variant="outlined"
                      fullWidth
                      value={formData.probation_end_date}
                      onChange={handleInputChange}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarMonth />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <TextField
                  name="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  value={formData.password}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Key />
                      </InputAdornment>
                    ),
                    endAdornment: formData.password && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={copyToClipboard}
                          edge="end"
                          title="Copy to clipboard"
                        >
                          <ContentCopy />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  gap: 2 
                }}>
                  <Button
                    variant="contained"
                    onClick={generatePassword}
                    sx={{ flex: 1 }}
                    disabled={loading}
                  >
                    Generate Password
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    endIcon={<ArrowForward />}
                    sx={{ flex: 1 }}
                    disabled={loading}
                  >
                    {loading ? 'Submitting...' : 'Submit'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default EmployeeLogin;