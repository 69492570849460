import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Typography, Card, CardContent, IconButton } from '@mui/material';
import { useAuth } from 'context/AuthContext';
import { 
  Dashboard, 
  People, 
  EventNote, 
  AccessTime,  
  AccountBalance,
  TimelineOutlined,
  WorkOutline,
  MonetizationOn,
  AssignmentInd,
  Group,
  CalendarToday,
  RequestQuote, 
  ManageHistory,
  CameraAlt,
  AssessmentOutlined, 
} from '@mui/icons-material';

const Homepage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  // All features available to admin (role_id === 1)
  const adminFeatures = [
    { 
      icon: <Dashboard sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Dashboard',
      description: 'Home Page',
      path: '/home'
    },
    { 
      icon: <People sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Employee MAnagement',
      description: 'Manage all employee information',
      path: '/Employeemenu'
    },
    // { 
    //   icon: <EventNote sx={{ fontSize: 40, color: '#FF9800' }} />, 
    //   title: 'Activity',
    //   description: 'Monitor employee attendance',
    //   path: '/add-activity'
    // },
    { 
      icon: <AccessTime sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Time-Sheet-Management',
      description: 'Track working hours and shifts',
      path: '/timesheetMenu'
    },
    { 
      icon: <WorkOutline sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Projects',
      description: 'Manage ongoing projects',
      path: '/project-menu'
    },
    // { 
    //   icon: <Assignment sx={{ fontSize: 40, color: '#FF9800' }} />, 
    //   title: 'Relieve-Table',
    //   description: 'For Relieving Employee',
    //   path: '/Relieve-employee'
    // },
    { 
      icon: <TimelineOutlined sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Performance',
      description: 'Track employee performance',
      path: '/Leavemenu'
    },
    { 
      icon: <AccountBalance sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Payroll',
      description: 'Manage employee salaries',
      path: ""
    },



    // { 
    //   icon: <MonetizationOn sx={{ fontSize: 40, color: '#FF9800' }} />, 
    //   title: 'Finance',
    //   description: 'Financial management tools',
    //   path: ""
    // }
  ];

  // Features available to manager (role_id === 2)
  const managerFeatures = [
    { 
      icon: <CameraAlt sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Mark-Attendence ',
      description: 'Mark your attendance Here ',
      path: '/cam'
    },
    { 
      icon: <RequestQuote sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Request Leave',
      description: 'Request for Leave',
      path: '/Leave-req'
    },
    { 
      icon: <ManageHistory sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Manage-Leave',
      description: 'Approve Leave Here',
      path: '/Leave-manage'
    },
    { 
      icon: <AssessmentOutlined sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Time Sheet Report',
      description: 'Monitor Timesheet Report',
      path: '/time-list'
    },
    { 
      icon: <EventNote sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Activity',
      description: 'Monitor employee attendance',
      path: '/add-activity'
    },
    { 
      icon: <WorkOutline sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Projects',
      description: 'Manage ongoing projects',
      path: '/add-project'
    }
  ];

  // Select features based on user role
  const features = user?.role_id === 1 ? adminFeatures : managerFeatures;

  const handleCardClick = (path) => {
    navigate(path);
  }

  return (
    <Box sx={{ mt: -11, minHeight: '100vh', pt: 0, pb: 6 }}>
      <Container maxWidth="lg">
        <Box sx={{ 
          textAlign: 'center', 
          mb: 8,
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-15px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '3px',
            backgroundColor: '#FF9800'
          }
        }}>
          <Typography
            component="h1"
            variant="h3"
            color="text.primary"
            gutterBottom
            sx={{ 
              fontWeight: 'bold',
              backgroundImage: 'linear-gradient(45deg, #FF9800, #FF5722)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent'
            }}
          >
            KEFI-LYNKS
          </Typography>
          <Typography variant="h6" color="text.secondary" paragraph>
            {user?.role_id === 1 
              ? 'Streamline your HR operations with our comprehensive management tools'
              : 'Manage your projects and activities efficiently'}
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                onClick={() => handleCardClick(feature.path)}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
                    '& .icon-button': {
                      color: 'blue'
                    }
                  }
                }}
              >
                <CardContent sx={{ 
                  flexGrow: 1, 
                  textAlign: 'center',
                  p: 4
                }}>
                  <IconButton 
                    className="icon-button"
                    sx={{ 
                      mb: 2, 
                      p: 2,
                      backgroundColor: 'rgba(255, 152, 0, 0.1)',
                      transition: 'all 0.3s ease'
                    }}
                  >
                    {feature.icon}
                  </IconButton>
                  <Typography gutterBottom variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    {feature.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Quick Stats Section - Only show for admin */}
        {user?.role_id === 1 && (
          <Box sx={{ mt: 8, mb: 4 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ 
                  p: 2, 
                  textAlign: 'center',
                  backgroundColor: 'rgba(255, 152, 0, 0.1)',
                  border: '1px solid #FF9800'
                }}>
                  <Group sx={{ fontSize: 40, color: '#FF9800', mb: 1 }} />
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#FF9800' }}>150+</Typography>
                  <Typography variant="subtitle1" color="text.secondary">Total Employees</Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ 
                  p: 2, 
                  textAlign: 'center',
                  backgroundColor: 'rgba(255, 152, 0, 0.1)',
                  border: '1px solid #FF9800'
                }}>
                  <AssignmentInd sx={{ fontSize: 40, color: '#FF9800', mb: 1 }} />
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#FF9800' }}>25</Typography>
                  <Typography variant="subtitle1" color="text.secondary">Active Projects</Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ 
                  p: 2, 
                  textAlign: 'center',
                  backgroundColor: 'rgba(255, 152, 0, 0.1)',
                  border: '1px solid #FF9800'
                }}>
                  <CalendarToday sx={{ fontSize: 40, color: '#FF9800', mb: 1 }} />
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#FF9800' }}>98%</Typography>
                  <Typography variant="subtitle1" color="text.secondary">Attendance Rate</Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ 
                  p: 2, 
                  textAlign: 'center',
                  backgroundColor: 'rgba(255, 152, 0, 0.1)',
                  border: '1px solid #FF9800'
                }}>
                  <MonetizationOn sx={{ fontSize: 40, color: '#FF9800', mb: 1 }} />
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#FF9800' }}>95%</Typography>
                  <Typography variant="subtitle1" color="text.secondary">Task Completion</Typography>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Homepage;