import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from 'context/AuthContext';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  Alert,
  Snackbar,
  CircularProgress,
  styled,
  Card,
  CardContent,
  useTheme,
  Grid
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SendIcon from '@mui/icons-material/Send';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Styled Components
const VideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  aspectRatio: '16/9',
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  marginBottom: theme.spacing(2)
}));

const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
});

const StyledImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  marginTop: theme.spacing(2)
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.default
}));

const AttendanceForm = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [photo, setPhoto] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const videoRef = useRef(null);
  const streamRef = useRef(null);

  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
      stopCamera();
    };
  }, []);

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => {
        track.stop();
      });
      streamRef.current = null;
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setIsCameraActive(false);
    }
  };

  const startCamera = async () => {
    try {
      stopCamera();

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        }
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
        setIsCameraActive(true);
        
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };
      }
    } catch (err) {
      console.error('Camera access error:', err);
      setError('Failed to access camera: ' + err.message);
      setIsCameraActive(false);
    }
  };

  const capturePhoto = () => {
    try {
      if (!videoRef.current || !streamRef.current) {
        setError('Camera not properly initialized');
        return;
      }

      const video = videoRef.current;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext('2d');
      if (!context) {
        setError('Failed to get canvas context');
        return;
      }

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        if (!blob) {
          setError('Failed to capture image');
          return;
        }

        setPhoto(blob);
        setPreviewUrl(URL.createObjectURL(blob));
        stopCamera();
      }, 'image/jpeg', 0.8);

    } catch (err) {
      console.error('Capture error:', err);
      setError('Failed to capture photo: ' + err.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('date_marked', currentTime.toISOString().split('T')[0]);
    formData.append('time_marked', currentTime.toTimeString().split(' ')[0]);
    formData.append('user_id', user.user_id);
    formData.append('image', photo, 'attendance.jpg');

    try {
      const response = await axios.post('http://127.0.0.1:8000/attendance', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }, withCredentials: true
      });

      if (response.data) {
        setSuccess(true);
        setPreviewUrl(null);
        setPhoto(null);
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to submit attendance');
    } finally {
      setLoading(false);
    }
  };

  const retakePhoto = () => {
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setPhoto(null);
    setPreviewUrl(null);
    startCamera();
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
    setError('');
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center" 
          sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
          Attendance System
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledCard>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                  <AccessTimeIcon color="primary" />
                  <Typography variant="h6">
                    {currentTime.toLocaleDateString()} - {currentTime.toLocaleTimeString()}
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {/* User: {user?.name || 'Not logged in'} */}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h5" gutterBottom align="center">
                Mark Attendance
              </Typography>

              <Box sx={{ my: 3 }}>
                {!previewUrl && (
                  <VideoContainer>
                    <StyledVideo
                      ref={videoRef}
                      autoPlay
                      playsInline
                      muted
                    />
                  </VideoContainer>
                )}

                {previewUrl && (
                  <VideoContainer>
                    <StyledImg
                      src={previewUrl}
                      alt="Preview"
                    />
                  </VideoContainer>
                )}

                <ButtonContainer>
                  {!previewUrl && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={startCamera}
                        disabled={loading}
                        startIcon={<CameraAltIcon />}
                        sx={{ minWidth: 150 }}
                      >
                        Start Camera
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={capturePhoto}
                        disabled={!isCameraActive || loading}
                        startIcon={<PhotoCameraIcon />}
                        sx={{ minWidth: 150 }}
                      >
                        Capture
                      </Button>
                    </>
                  )}

                  {previewUrl && (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={retakePhoto}
                        disabled={loading}
                        sx={{ minWidth: 150 }}
                      >
                        Retake Photo
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                        sx={{ minWidth: 150 }}
                      >
                        {loading ? 'Submitting...' : 'Mark Attendance'}
                      </Button>
                    </>
                  )}
                </ButtonContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Snackbar
          open={!!error || success}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={error ? 'error' : 'success'}
            variant="filled"
          >
            {error || 'Attendance marked successfully!'}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default AttendanceForm;