import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  ButtonGroup,
  Card,
  CardContent,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  styled,
} from '@mui/material';
import { 
  SearchRounded, 
  PictureAsPdf, 
  TableView, 
  NavigateNext, 
  NavigateBefore,
  FilterAlt,
  Clear,
  CalendarMonth,
  DateRange
} from '@mui/icons-material';

// Styled Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'white',
  fontWeight: 600,
  padding: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[3],
}));

const TimesheetList = () => {
  const theme = useTheme();
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [nameSearch, setNameSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');
  const [allProjects, setAllProjects] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('list');
  const [downloading, setDownloading] = useState({
    pdf: false,
    xlsx: false
  });

  // Fetch timesheets
  const fetchTimesheets = async () => {
    try {
      setLoading(true);
      setError(null);

      const params = new URLSearchParams({
        page: currentPage,
        items_per_page: 10
      });

      if (nameSearch) params.append('name_search', nameSearch);
      if (projectSearch) params.append('project_search', projectSearch);
      
      if (startDate) {
        const startDateTime = new Date(startDate);
        startDateTime.setHours(0, 0, 0, 0);
        params.append('start_date', startDateTime.toISOString());
      }

      if (endDate) {
        const endDateTime = new Date(endDate);
        endDateTime.setHours(23, 59, 59, 999);
        params.append('end_date', endDateTime.toISOString());
      }

      const response = await axios.get(
        `http://localhost:8000/timesheets/submitted/?${params}`,
        {
          headers: { 'Accept': 'application/json' }
        }
      );

      if (response.data && Array.isArray(response.data.data)) {
        setTimesheets(response.data.data);
        setTotalPages(response.data.total_pages);
      } else {
        throw new Error('Invalid data format received from server');
      }
    } catch (error) {
      console.error('Error fetching timesheets:', error);
      setError(error.response?.data?.detail || error.message || 'Error fetching timesheets');
    } finally {
      setLoading(false);
    }
  };

  // Download functionality
  const downloadFile = async (format) => {
    try {
      setDownloading(prev => ({ ...prev, [format]: true }));
      setError(null);

      const params = new URLSearchParams();
      params.append('format', format);
      if (nameSearch) params.append('name_search', nameSearch);
      if (projectSearch) params.append('project_search', projectSearch);
      if (startDate) params.append('start_date', startDate);
      if (endDate) params.append('end_date', endDate);

      const response = await axios.get(
        `http://localhost:8000/timesheets/export/?${params}`,
        { responseType: 'blob' }
      );

      const blob = new Blob([response.data], {
        type: format === 'pdf' 
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `timesheets.${format}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(`Error downloading ${format}:`, error);
      setError(`Failed to download ${format.toUpperCase()} file`);
    } finally {
      setDownloading(prev => ({ ...prev, [format]: false }));
    }
  };

  // Group by Month
  const groupByMonth = (data) => {
    return data.reduce((acc, item) => {
      const date = new Date(item.current_date);
      const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(item);
      return acc;
    }, {});
  };

  // Group by Week
  const groupByWeek = (data) => {
    return data.reduce((acc, item) => {
      const date = new Date(item.current_date);
      const weekNumber = getWeekNumber(date);
      if (!acc[weekNumber]) {
        acc[weekNumber] = [];
      }
      acc[weekNumber].push(item);
      return acc;
    }, {});
  };

  // Get Week Number
  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return `Week ${weekNo}, ${d.getFullYear()}`;
  };

  // Format Date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) throw new Error('Invalid date');
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (err) {
      console.error('Date formatting error:', err);
      return dateString;
    }
  };

  // Clear Filters
  const handleClearFilters = () => {
    setNameSearch('');
    setProjectSearch('');
    setStartDate('');
    setEndDate('');
    setCurrentPage(1);
  };

  // Handle Page Change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Effects
  useEffect(() => {
    fetchTimesheets();
  }, [currentPage, nameSearch, projectSearch, startDate, endDate]);

  useEffect(() => {
    if (timesheets.length > 0) {
      const uniqueProjects = [...new Set(timesheets.map(ts => ts.project_name))];
      setAllProjects(uniqueProjects.sort());
    }
  }, [timesheets]);

  // Render Timesheet Table
  const renderTimesheetTable = (data) => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: theme.palette.primary.main }}>
            <StyledTableCell>Employee Name</StyledTableCell>
            <StyledTableCell>Project</StyledTableCell>
            <StyledTableCell>Activity</StyledTableCell>
            <StyledTableCell>Hours</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((timesheet, index) => (
            <TableRow 
              key={index} 
              hover
              sx={{ '&:nth-of-type(odd)': { bgcolor: 'action.hover' } }}
            >
              <TableCell sx={{ fontWeight: 500 }}>
                {timesheet.first_name} {timesheet.last_name}
              </TableCell>
              <TableCell>{timesheet.project_name}</TableCell>
              <TableCell>{timesheet.activity_name}</TableCell>
              <TableCell>{timesheet.hours_worked}</TableCell>
              <TableCell>{formatDate(timesheet.current_date)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Render Content Based on View Mode
  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (timesheets.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', p: 4 }}>
          <Typography variant="body1" color="text.secondary">
            No timesheets found
          </Typography>
        </Box>
      );
    }

    switch (viewMode) {
      case 'month':
        const monthlyData = groupByMonth(timesheets);
        return Object.entries(monthlyData).map(([month, entries]) => (
          <StyledCard key={month}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, color: theme.palette.primary.main }}>
                {month}
              </Typography>
              {renderTimesheetTable(entries)}
            </CardContent>
          </StyledCard>
        ));

      case 'week':
        const weeklyData = groupByWeek(timesheets);
        return Object.entries(weeklyData).map(([week, entries]) => (
          <StyledCard key={week}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, color: theme.palette.primary.main }}>
                {week}
              </Typography>
              {renderTimesheetTable(entries)}
            </CardContent>
          </StyledCard>
        ));

      default:
        return renderTimesheetTable(timesheets);
    }
  };

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, mt:-11, maxWidth: '1400px', mx: 'auto' }}>
      <StyledCard>
        <CardContent>
          <Typography variant="h5" sx={{ mb: 3, color: theme.palette.primary.main, fontWeight: 600 }}>
            Timesheet Management
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 3, borderRadius: 1 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={viewMode} 
              onChange={(e, newValue) => setViewMode(newValue)}
              indicatorColor="primary"
            >
              <Tab 
                icon={<TableView />} 
                label="List View" 
                value="list"
                sx={{ textTransform: 'none' }}
              />
              <Tab 
                icon={<CalendarMonth />} 
                label="Month View" 
                value="month"
                sx={{ textTransform: 'none' }}
              />
              <Tab 
                icon={<DateRange />} 
                label="Week View" 
                value="week"
                sx={{ textTransform: 'none' }}
              />
            </Tabs>
          </Box>

          <Box sx={{ 
            display: 'grid', 
            gap: 2,
            gridTemplateColumns: { xs: '1fr', md: '2fr 2fr 1fr' },
            mb: 3
          }}>
            <TextField
              label="Employee Name"
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
              InputProps={{
                startAdornment: <SearchRounded sx={{ color: 'text.secondary', mr: 1 }} />,
              }}
              size="small"
            />

            <FormControl size="small">
              <InputLabel>Project</InputLabel>
              <Select
                value={projectSearch}
                onChange={(e) => setProjectSearch(e.target.value)}
                label="Project"
              >
                <MenuItem value="">All Projects</MenuItem>
                {allProjects.map((project) => (
                  <MenuItem key={project} value={project}>{project}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                type="date"
                label="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
              <TextField
                type="date"
                label="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
            <ButtonGroup variant="contained">
              <Button
                startIcon={<FilterAlt />}
                onClick={fetchTimesheets}
                disabled={loading}
              >
                Filter
              </Button>
              <Button
                startIcon={<Clear />}
                onClick={handleClearFilters}
                disabled={loading}
              >
                Clear
              </Button>
            </ButtonGroup>

            <ButtonGroup variant="outlined">
              <Button
                startIcon={<PictureAsPdf />}
                onClick={() => downloadFile('pdf')}
                disabled={downloading.pdf || loading}
              >
                {downloading.pdf ? <CircularProgress size={24} /> : 'PDF'}
              </Button>
              <Button
                startIcon={<TableView />}
                onClick={() => downloadFile('xlsx')}
                disabled={downloading.xlsx || loading}
              >
                {downloading.xlsx ? <CircularProgress size={24} /> : 'Excel'}
              </Button>
            </ButtonGroup>
          </Box>
        </CardContent>
      </StyledCard>

      <StyledCard>
        {renderContent()}
        
        {viewMode === 'list' && (
  <Box sx={{ 
    p: 2, 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    borderTop: 1,
    borderColor: 'divider'
  }}>
    <Typography variant="body2" color="text.secondary">
      Page {currentPage} of {totalPages}
    </Typography>
    <ButtonGroup size="small">
      <Button
        startIcon={<NavigateBefore />}
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1 || loading}
      >
        Previous
      </Button>
      <Button
        endIcon={<NavigateNext />}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages || loading}
      >
        Next
      </Button>
    </ButtonGroup>
  </Box>
)}
</StyledCard>
    </Box>
  );
};

export default TimesheetList;