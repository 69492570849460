import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './Components/Navbar';
import { AuthProvider, useAuth } from './context/AuthContext';
import EmployeeMenu from 'Employepage/EmployeeMenu';
import TimesheetMenu from 'Timesheet/TimesheetMenu';
import ProjectMenu from 'Projects/ProjectMenu';
import LeaveMenu from 'LeaveManagement/LeaveMenu';

// Page imports
import Login from './LoginForm/Login';
import Homepage from './Homepage/Homepage';
import Empadd from './Employepage/Empadd';
import Emplogindetails from './Employepage/Emplogindetails';
import TimeSheet from './Timesheet/Timesheet';
import Tables from './Components/Tables';
// import Timeapp from './Timesheet/Timeapp';
// import Employeelist from './Employepage/Employeelist';
import Timesheetreport from './Timesheet/Timesheetreport';
import EnhancedTable from './Timesheet/EnhancedTable';
import Addproject from './Projects/Addproject';
import Addactivity from './Projects/Addactivity';
import ProjectAssign from './Projects/Projectassign';
import TimesheetList from './Timesheet/TimesheetList';
import EmployeeRelieving from './Employepage/EmployeeRelieving';
import LeaveRequestForm from 'LeaveManagement/LeaveRequestForm';
import LeaveRequestsTable from 'LeaveManagement/LeaveRequestTable';
import LeaveApproval from 'LeaveManagement/LeaveRequestApprovel';
import DateListSorter from 'Datesorting/DateList';
import ProjectList from 'Projects/ProjectList';
import ActivityList from 'Projects/ActivityList';
import AttendanceForm from 'AttendancePage/AttendanceForm';
import AttendanceList from 'AttendancePage/AttendanceList';

// Protected Route Component

const ProtectedRoute = ({ children }) => {
  const { user, logout } = useAuth();

  useEffect(() => {
    if (!user) {
      // If user is not logged in, perform cleanup and redirect
      logout(); // Clear user state and perform any other necessary actions
    }
  }, [user, logout]);

  return user ? children : <Navigate to="/" />;
};

// Layout Component for Protected Routes
const ProtectedLayout = ({ children }) => (
  <div>
    <Navbar />
    <div style={{ 
      paddingTop: '64px', 
      minHeight: '100vh', 
      background: '#f3f4f6'
    }}>
      {children}
    </div>
  </div>
);

// Create a single theme instance
const theme = createTheme({
  palette: {
    background: {
      default: '#f3f4f6',
      paper: '#ffffff',
    },
    primary: {
      main: '#3f51b5',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <Routes>
            {/* Public Route */}
            <Route path="/" element={<Login />} />
            <Route path="/date" element={<DateListSorter/>} />
           

            {/* Protected Routes */}
            {/* Dashboard/Home */}
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <Homepage />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/cam"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <AttendanceForm/>
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/atlist"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <AttendanceList/>
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />


            <Route
              path="/Employeemenu"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <EmployeeMenu />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/add"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <Empadd />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/det"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <Emplogindetails />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/list"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <Employeelist />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/Relieve-employee"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <EmployeeRelieving />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />

            {/* Time Management Routes */}
            <Route
              path="/time"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <TimeSheet />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/timesheetMenu"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <TimesheetMenu />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/time-report"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <Timesheetreport />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/time-list"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <EnhancedTable />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/submited-Time"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <TimesheetList />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />

            {/* Project Management Routes */}
 



            <Route
              path="/project-menu"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <ProjectMenu/>
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/plist"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <ProjectList/>
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/alist"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <ActivityList/>
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/add-project"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <Addproject />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-activity"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <Addactivity />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/assign-project"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <ProjectAssign />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />

            {/* Additional Routes */}
            <Route
              path="/table"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <Tables />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/excel"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <TimesheetList />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/Leavemenu"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <LeaveMenu />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/Leave-req"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <LeaveRequestForm />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/Leave-manage"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <LeaveRequestsTable />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/approve-leave"
              element={
                <ProtectedRoute>
                  <ProtectedLayout>
                    <LeaveApproval />
                  </ProtectedLayout>
                </ProtectedRoute>
              }
            />
            
            {/* Catch all route - Redirect to home */}
            <Route
              path="*"
              element={
                <Navigate to="/home" replace />
              }
            />
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;