import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Snackbar,
  Alert,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Label,
  Code,
  Description,
  Send
} from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from 'context/AuthContext';
// import { useDispatch } from 'react-redux';

const Addactivity = () => {
  const { AddActivity,fetchProject } = useAuth();
  // const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [activities, setActivities] = useState([]);
  const [currentActivity, setCurrentActivity] = useState({
    name: '',
    code: '',
    description: '',
    start_date: '',
    end_date: ''
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchProjects();
  },[]);

  const fetchProjects = async () => {
    try {
      const response = await fetchProject();
      setProjects(response);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: error.response?.status === 401 
            ? "Authentication failed. Please login again."
            : "Failed to load projects"
        });
      }
      console.error("Failed to fetch projects:", error);
    }
  };

  const handleActivityChange = (e) => {
    setCurrentActivity(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const addActivity = () => {
    if (!currentActivity.name.trim()) {
      setSnackbar({
        open: true,
        message: 'Please enter an activity name',
        severity: 'error'
      });
      return;
    }

    if (new Date(currentActivity.end_date) < new Date(currentActivity.start_date)) {
      setSnackbar({
        open: true,
        message: 'End date cannot be before start date',
        severity: 'error'
      });
      return;
    }

    setActivities([...activities, { ...currentActivity }]);
    setCurrentActivity({
      name: '',
      code: '',
      description: '',
    });
  };

  const removeActivity = (index) => {
    setActivities(activities.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (!selectedProject) {
      setSnackbar({
        open: true,
        message: 'Please select a project',
        severity: 'error'
      });
      return;
    }

    if (activities.length === 0) {
      setSnackbar({
        open: true,
        message: 'Please add at least one activity',
        severity: 'error'
      });
      return;
    }

    setLoading(true);
    try {
      for (const activity of activities) {
        const activityData = {
          name: activity.name,
          code: activity.code,
          description: activity.description,
          project_id: selectedProject
        };
        
        const response = await AddActivity(activityData);
      }
      
      setSnackbar({
        open: true,
        message: 'Activities added successfully!',
        severity: 'success'
      });
      
      setActivities([]);
      setSelectedProject('');
      
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'Error adding activities',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 ,mt:-11}}>
        <Typography 
            variant="h5" 
            component="h1" 
            gutterBottom
            sx={{ 
              mb: 4, 
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
          <AddIcon /> Add Activities
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select Project</InputLabel>
                <Select
                  value={selectedProject}
                  onChange={(e) => setSelectedProject(e.target.value)}
                  label="Select Project"
                  required
                >
                  <MenuItem value="">Select Project</MenuItem>
                  {projects.map(proj => (
                    <MenuItem key={proj.id} value={proj.id}>
                      {proj.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={1} sx={{ p: 3, bgcolor: 'background.paper' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Activity Name"
                      name="name"
                      value={currentActivity.name}
                      onChange={handleActivityChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Label />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Code"
                      name="code"
                      value={currentActivity.code}
                      onChange={handleActivityChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Code />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      value={currentActivity.description}
                      onChange={handleActivityChange}
                      multiline
                      rows={3}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Description />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>  

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={addActivity}
                      startIcon={<AddIcon />}
                    >
                      Add Activity
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {activities.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Added Activities
                </Typography>
                <List>
                  {activities.map((activity, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          primary={activity.name}
                          secondary={
                            <>
                              <Typography component="span" variant="body2">
                                {activity.description}
                              </Typography>
                              <br />
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" onClick={() => removeActivity(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>

                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Send />}
                    disabled={loading}
                  >
                    {loading ? 'Submitting...' : 'Submit All Activities'}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Addactivity;