import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

const DateListSorter = () => {
  // Sample data with different dates
  const initialData = [
    { id: 1, date: '2024-01-15', title: 'Project Alpha Launch', status: 'Completed' },
    { id: 2, date: '2024-02-20', title: 'Team Meeting', status: 'Pending' },
    { id: 3, date: '2024-01-25', title: 'Client Presentation', status: 'In Progress' },
    { id: 4, date: '2024-03-05', title: 'Training Session', status: 'Scheduled' },
    { id: 5, date: '2024-02-10', title: 'Release 2.0', status: 'Completed' },
    { id: 6, date: '2024-03-15', title: 'Budget Review', status: 'Pending' },
    { id: 7, date: '2024-01-05', title: 'Annual Planning', status: 'Completed' },
    { id: 8, date: '2024-02-28', title: 'Product Demo', status: 'Scheduled' },
  ];

  const [sortBy, setSortBy] = useState('all');
  const [data] = useState(initialData);

  // Function to get month name
  const getMonthName = (date) => {
    return new Date(date).toLocaleString('default', { month: 'long' });
  };

  // Function to get week number
  const getWeekNumber = (date) => {
    const currentDate = new Date(date);
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startOfYear) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + startOfYear.getDay() + 1) / 7);
  };

  // Organize data by month and week
  const organizedData = useMemo(() => {
    const monthData = {};
    const weekData = {};

    data.forEach(item => {
      const month = getMonthName(item.date);
      const week = getWeekNumber(item.date);

      // Organize by month
      if (!monthData[month]) {
        monthData[month] = [];
      }
      monthData[month].push(item);

      // Organize by week
      if (!weekData[week]) {
        weekData[week] = [];
      }
      weekData[week].push(item);
    });

    return { monthData, weekData };
  }, [data]);

  const renderMonthView = () => {
    return Object.entries(organizedData.monthData).map(([month, items]) => (
      <Card key={month} sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" color="primary" gutterBottom>
            {month}
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{new Date(item.date).toLocaleDateString()}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    ));
  };

  const renderWeekView = () => {
    return Object.entries(organizedData.weekData).map(([week, items]) => (
      <Card key={week} sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6" color="primary" gutterBottom>
            Week {week}
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{new Date(item.date).toLocaleDateString()}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>{item.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    ));
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', p: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Event Timeline
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>View By</InputLabel>
              <Select
                value={sortBy}
                label="View By"
                onChange={(e) => setSortBy(e.target.value)}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="week">Week</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {sortBy === 'month' && renderMonthView()}
            {sortBy === 'week' && renderWeekView()}
            {sortBy === 'all' && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{new Date(item.date).toLocaleDateString()}</TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default DateListSorter;