import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Typography, Card, CardContent, IconButton } from '@mui/material';
import { useAuth } from 'context/AuthContext';
import { 
  RequestQuote, 
  ManageHistory, 
  CameraAlt,
  TimelineOutlined,
  WorkOutline
} from '@mui/icons-material';

const LeaveMenu = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  // All features available to admin (role_id === 1)
  const adminFeatures = [
    { 
      icon: <RequestQuote sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Request Leave',
      description: 'Request for Leave',
      path: '/Leave-req'
    },
    { 
      icon: <ManageHistory sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Manage-Leave',
      description: 'Approve Leave Here',
      path: '/Leave-manage'
    },
    { 
      icon: <CameraAlt sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Mark-Attendence ',
      description: 'Mark your attendance Here ',
      path: '/cam'
    },
    { 
      icon: <TimelineOutlined sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Attendence',
      description: 'Daily-Attendence',
      path: '/atlist'
    }
  ];

  // Features available to manager (role_id === 2)
  const managerFeatures = [
    { 
      icon: <TimelineOutlined sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Activity',
      description: 'Monitor employee attendance',
      path: '/add-activity'
    },
    { 
      icon: <WorkOutline sx={{ fontSize: 40, color: '#FF9800' }} />, 
      title: 'Projects',
      description: 'Manage ongoing projects',
      path: '/add-project'
    },

  ];

  // Select features based on user role
  const features = user?.role_id === 1 ? adminFeatures : managerFeatures;

  const handleCardClick = (path) => {
    navigate(path);
  }

  return (
    <Box sx={{ mt: -11, minHeight: '100vh', pt: 0, pb: 6 }}>
      <Container maxWidth="lg">
        <Box sx={{ 
          textAlign: 'center', 
          mb: 8,
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-15px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '3px',
            backgroundColor: '#FF9800'
          }
        }}>
          <Typography
            component="h1"
            variant="h3"
            color="text.primary"
            gutterBottom
            sx={{ 
              fontWeight: 'bold',
              backgroundImage: 'linear-gradient(45deg, #FF9800, #FF5722)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent'
            }}
          >
            KEFI-LYNKS
          </Typography>
          <Typography variant="h6" color="text.secondary" paragraph>
            {user?.role_id === 1 
              ? 'Manage your Leave Request'
              : 'Manage your projects and activities efficiently'}
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                onClick={() => handleCardClick(feature.path)}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
                    '& .icon-button': {
                      color: 'blue'
                    }
                  }
                }}
              >
                <CardContent sx={{ 
                  flexGrow: 1, 
                  textAlign: 'center',
                  p: 4
                }}>
                  <IconButton 
                    className="icon-button"
                    sx={{ 
                      mb: 2, 
                      p: 2,
                      backgroundColor: 'rgba(255, 152, 0, 0.1)',
                      transition: 'all 0.3s ease'
                    }}
                  >
                    {feature.icon}
                  </IconButton>
                  <Typography gutterBottom variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    {feature.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default LeaveMenu;