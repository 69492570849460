import { 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Button,
  Snackbar,
  Alert,
  Paper,
  Typography,
  Box,
  Container,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  createTheme,
  ThemeProvider,
  styled
} from '@mui/material';
import { useAuth } from 'context/AuthContext';
import {
  Assignment as AssignmentIcon,
} from '@mui/icons-material';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#004c97',
      light: '#3370ab',
      dark: '#003569',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff6c0e',
      light: '#ff8c3e',
      dark: '#b24b09',
      contrastText: '#ffffff',
    },
  },
});

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  borderTop: `4px solid ${theme.palette.secondary.main}`,
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: theme.spacing(1),
  '& .MuiCheckbox-root': {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
  },
  '& .MuiTypography-root': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    '& .MuiCheckbox-root': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const ProjectAssign = () => {
  const { fetch_Details } = useAuth();
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: ''
  });
  
    useEffect(() => {
      fetchData();
    },[]);
  
    
    useEffect(() => {
      if (selectedProject) {
        console.log(selectedProject,"prooooo");
        console.log(activities,"siuui");
        
        
        const projectActivities = activities.filter(activity => {
            const isMatch = String(activity.project_id) === String(selectedProject);
            console.log(`Activity ${activity.project_id}: ${isMatch ? 'matches' : 'does not match'}`);
            return isMatch;
          });
        setFilteredActivities(projectActivities);
      } else {
        setFilteredActivities([]);
      }
     
    }, [selectedProject, activities]);
  
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch_Details();
        console.log("API Response:", response);
        
        const { data: responseData = {} } = response || {};
        
        setUsers(Array.isArray(responseData.users) ? responseData.users : []);
        setProjects(Array.isArray(responseData.projects) ? responseData.projects : []);
        setActivities(Array.isArray(responseData.activities) ? responseData.activities : []);
        
        console.log("Processed Data:", {
          users: responseData.users,
          projects: responseData.projects,
          activities: responseData.activities
        });
      } catch (error) {
        console.error("API Error:", error);
        
        if (axios.isAxiosError(error)) {
          switch(error.response?.status) {
            case 401:
              setSnackbar({
                open: true,
                severity: 'error',
                message: "Authentication failed. Please login again."
              });
              break;
              
            case 403:
              setSnackbar({
                open: true,
                severity: 'error',
                message: "You don't have permission to access this data"
              });
              break;
              
            case 404:
              setSnackbar({
                open: true,
                severity: 'error',
                message: "Data not found"
              });
              break;
              
            default:
              setSnackbar({
                open: true,
                severity: 'error',
                message: error.response?.data?.detail || "Failed to load data"
              });
          }
        }
      } finally {
        setLoading(false);
      }
    };
  
    const handleActivityCheck = (activityId) => {
      setSelectedActivities(prev => {
        if (prev.includes(activityId)) {
          return prev.filter(id => id !== activityId);
        } else {
          return [...prev, activityId];
        }
      });
    };
  
    const handleSubmit = async () => {
      if (!selectedUser || !selectedProject || selectedActivities.length === 0) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please select all required fields and at least one activity'
        });
        return;
      }
  
      setLoading(true);
      try {
        for (const activityId of selectedActivities) {
          const assignmentData = {
            user_id: selectedUser,
            project_id: selectedProject,
            activity_id: activityId
          };
  
          await axios.post(
            "http://127.0.0.1:8000/projectassign", 
            assignmentData,  
            {  
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            }
          );
        }
      
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Activities assigned successfully!'
        });
  
        setSelectedUser('');
        setSelectedProject('');
        setSelectedActivities([]);
        
      } catch (error) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: error.response?.data?.message || 'Failed to assign activities'
        });
      } finally {
        setLoading(false);
      }
    };
  
    const handleCloseSnackbar = () => {
      setSnackbar(prev => ({ ...prev, open: false }));
    };
  
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 4 }}>
          <Container maxWidth="sm">
            <StyledPaper elevation={3} sx={{ mt: -11 }}>
              <Typography 
                variant="h5" 
                component="h1" 
                gutterBottom
                sx={{ 
                  mb: 4, 
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  color: 'primary.main'
                }}
              >
                <AssignmentIcon sx={{ color: 'secondary.main' }} />
                Project Assignment
              </Typography>
  
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Select Project</InputLabel>
                    <Select
                      value={selectedProject}
                      onChange={(e) => {
                        setSelectedProject(e.target.value);
                        setSelectedActivities([]);
                      }}
                      label="Select Project"
                      required
                    >
                      <MenuItem value="">
                        <em>Select a Project</em>
                      </MenuItem>
                      {projects.map(proj => (
                        <MenuItem key={proj.project_id} value={proj.project_id}>
                          {proj.project_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
  
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Select User</InputLabel>
                    <Select
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      label="Select User"
                      required
                    >
                      <MenuItem value="">
                        <em>Select a User</em>
                      </MenuItem>
                      {users.map(user => (
                        <MenuItem key={user.user_id} value={user.user_id}>
                          {`${user.first_name} ${user.last_name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
  
                {selectedProject && (
                  <Grid item xs={12}>
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        mb: 2, 
                        color: 'primary.main',
                        fontWeight: 500 
                      }}
                    >
                      Select Activities:
                    </Typography>
                    <Paper 
                      variant="outlined" 
                      sx={{ 
                        p: 2, 
                        bgcolor: 'background.paper',
                        borderColor: 'primary.light'
                      }}
                    >
                      <FormGroup>
                        {filteredActivities.map(activity => (
                          <StyledFormControlLabel
                            key={activity.activity_id}
                            control={
                              <Checkbox
                                checked={selectedActivities.includes(activity.activity_id)}
                                onChange={() => handleActivityCheck(activity.activity_id)}
                              />
                            }
                            label={activity.activity_name}
                          />
                        ))}
                      </FormGroup>
                    </Paper>
                  </Grid>
                )}
  
                <Grid item xs={12}>
                  <Button 
                    variant="contained" 
                    fullWidth 
                    onClick={handleSubmit}
                    disabled={loading || !selectedUser || !selectedProject || selectedActivities.length === 0}
                    sx={{ 
                      mt: 2,
                      py: 1.5,
                      fontSize: '1.1rem',
                      position: 'relative',
                      bgcolor: 'secondary.main',
                      '&:hover': {
                        bgcolor: 'secondary.dark',
                      },
                      '&.Mui-disabled': {
                        bgcolor: 'action.disabledBackground',
                      }
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Assign Activities'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </StyledPaper>
  
            <Snackbar 
              open={snackbar.open} 
              autoHideDuration={6000} 
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert 
                onClose={handleCloseSnackbar} 
                severity={snackbar.severity}
                variant="filled"
                sx={{ 
                  width: '100%',
                  ...(snackbar.severity === 'success' && {
                    bgcolor: 'secondary.main'
                  })
                }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Container>
        </Box>
      </ThemeProvider>
    );
  };
  
  export default ProjectAssign;