import './Tables.scss';
import { Link } from 'react-router-dom';
import Navbar from 'Components/Navbar';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';


function Tables() {
  const [user,setUsers]=useState([])
  const [error, setError] = useState(null);
  useEffect(()=>{
    fetchProjects()
    
  },[])

  const fetchProjects = async () => {
    try {
        const response = await axios.get("http://127.0.0.1:8000/get-users");
        setUsers(response.data);
    } catch (error) {
        console.error("Failed to fetch projects:", error);
        setError("Failed to load projects");
    }
};

  return (
    <div className="tables-container">
      
      <main>
        <div className="container mx-auto px-4">
          <div className="header-section">
            <h1>Tables</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
              <Link className="nav-link" to="/home"> 
                  <div className="sb-nav-link-icon"><i className="fas fa-table" /> &nbsp;HOME</div>
              </Link>
              </li>
              <li className="breadcrumb-item active">Tables</li>
            </ol>
          </div>

          <div className="card">
            <div className="card-header">
              <h2>
                <i className="fas fa-table me-2"></i>
                Login History
              </h2>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table id="datatablesSimple">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Employee ID</th>
                      
                      <th>Role</th>
                      <th>LOgin Time</th>
                      <th>logout Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                  {user.map((item) => (
                        <tr key={item.employee_id }>
                            <td>{item.first_name} {item.last_name}</td>
                            <td>{item.employee_id}</td>
                            <td>{item.last_name}</td>
                            <td>{item.login_time}</td>
                            <td>-</td>
                            <td>-</td>
                       
                        </tr>
                    ))}


  
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Tables;