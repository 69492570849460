import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Container,
  Typography,
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';

const Empadd = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formData = location.state || {};
  
  // Form state
  const [formValues, setFormValues] = useState({
    full_name: '',
    joining_date: '',
    personal_email: '',
    phone_number: '',
    address: '',
    position: '',
    image: '',
    adhar_number: '',
    pan_number: '',
    uan_number: '',
    blood_group: '',
    date_of_birth: '',
    employmentType: '',
    probationStartDate: '',
    probation_end_date: '',
    first_name: formData.firstName || '',
    last_name: formData.lastName || '',
    employee_id: formData.employeeId || '',
    office_email: formData.officeEmail || '',
    password: formData.password || ''
  });

  const requiredFields = ['firstName', 'lastName', 'officeEmail', 'employeeId', 'password'];

  useEffect(() => {
    const missingFields = requiredFields.filter(field => !formData[field]);
    // if (missingFields.length > 0) {
    //   alert(`Missing fields: ${missingFields.join(', ')}`);
    //   navigate('/det');
    //   return;
    // }
  }, [formData, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEmploymentTypeChange = (event) => {
    const { checked, value } = event.target;
    setFormValues(prev => ({
      ...prev,
      employmentType: checked ? value : ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);
    try {
      console.log(formValues);
      await axios.post('http://127.0.0.1:8000/user', formValues);
      navigate("/home");
    } catch (error) {
      console.error('Error during fetch:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Box sx={{  minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Typography variant="h4" component="h2" align="center" gutterBottom>
            Add Employee
          </Typography>

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="full_name"
                  value={formValues.full_name}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Personal Email"
                  name="personal_email"
                  type="email"
                  value={formValues.personal_email}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Designation"
                  name="position"
                  value={formValues.position}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Reporting Manager</InputLabel>
                  <Select
                    label="Reporting Manager"
                    name="reporting_manager"
                    value={formValues.reporting_manager || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value="Kefi">Kefi</MenuItem>
                    <MenuItem value="Saab">Saab</MenuItem>
                    <MenuItem value="Mercedes">Mercedes</MenuItem>
                    <MenuItem value="Audi">Audi</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Joining Date"
                  name="joining_date"
                  type="date"
                  value={formValues.joining_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.employmentType === 'Probation'}
                        onChange={handleEmploymentTypeChange}
                        value="Probation"
                        name="employmentType"
                      />
                    }
                    label="Probation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.employmentType === 'Permanent'}
                        onChange={handleEmploymentTypeChange}
                        value="Permanent"
                        name="employmentType"
                      />
                    }
                    label="Permanent"
                  />
                </Box>
              </Grid>

              {formValues.employmentType === 'Probation' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Probation Start Date"
                      name="probationStartDate"
                      type="date"
                      value={formValues.probationStartDate}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Probation End Date"
                      name="probation_end_date"
                      type="date"
                      value={formValues.probation_end_date}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Contact Number"
                  name="phone_number"
                  value={formValues.phone_number}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  value={formValues.address}
                  onChange={handleChange}
                  required
                  multiline
                  rows={2}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Date of Birth"
                  name="date_of_birth"
                  type="date"
                  value={formValues.date_of_birth}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Blood Group"
                  name="blood_group"
                  value={formValues.blood_group}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Aadhaar Number"
                  name="adhar_number"
                  value={formValues.adhar_number}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="PAN Number"
                  name="pan_number"
                  value={formValues.pan_number}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="UAN Number"
                  name="uan_number"
                  value={formValues.uan_number}
                  onChange={handleChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Image URL"
                  name="image"
                  value={formValues.image}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/det')}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Empadd;