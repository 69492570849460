import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  CircularProgress, 
  IconButton,
  Tooltip,
  Alert,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  styled,
  createTheme,
  ThemeProvider,
  Snackbar
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Visibility as VisibilityIcon,
  Assignment as AssignmentIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from 'context/AuthContext';

// Custom theme with brand colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#004c97',
      light: '#3370ab',
      dark: '#003569',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff6c0e',
      light: '#ff8c3e',
      dark: '#b24b09',
      contrastText: '#ffffff',
    },
  },
});

// Styled Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(3),
  fontSize: '1rem',
  '&.MuiTableCell-head': {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .MuiTableCell-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  '&.MuiButton-outlinedPrimary': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main + '10',
    },
  },
}));

const TimeReportTable = () => {
  const { user } = useAuth();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchReports = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.post("http://127.0.0.1:8000/fetch-time-report",{user_id:user.role_id},{
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      
      const processedData = response.data.map((report, index) => ({
        ...report,
        id: report.id || `temp-${index}`,
        formattedDate: new Date(report.current_date).toLocaleDateString()
      }));
      
      setReports(processedData);
      setSnackbar({
        open: true,
        message: 'Time reports loaded successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error("Failed to fetch time reports:", error);
      setError('Failed to fetch time reports. Please try again later.');
      setSnackbar({
        open: true,
        message: 'Failed to load time reports',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 4, maxWidth: '1600px', mx: 'auto',mt:-11 }}>
        <Card elevation={3}>
          <CardContent sx={{ p: 4 }}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  mb: 4, 
                  fontWeight: 600, 
                  color: 'primary.main',
                  borderBottom: '3px solid',
                  borderColor: 'secondary.main',
                  pb: 1
                }}
              >
                Time Reports
              </Typography>
              <Tooltip title="Refresh Reports">
                <IconButton 
                  onClick={fetchReports}
                  sx={{ 
                    color: 'primary.main',
                    mb: 4,
                    '&:hover': {
                      color: 'secondary.main'
                    }
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>

            {/* Error Alert */}
            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 4,
                  borderRadius: 1
                }}
                onClose={() => setError(null)}
              >
                {error}
              </Alert>
            )}

            {/* Main Table */}
            <TableContainer component={Paper} sx={{ mb: 4, boxShadow: 3 }}>
              <Table sx={{ minWidth: 1200 }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: 'primary.main' }}>
                    <StyledTableCell>Employee Name</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Employee Code</StyledTableCell>
                    {/* <StyledTableCell>Activity</StyledTableCell> */}
                    <StyledTableCell>Hours Worked</StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.map((report) => (
                    <StyledTableRow key={report.id}>
                      <StyledTableCell sx={{ fontWeight: 500, color: 'primary.main' }}>
                        {`${report.first_name} ${report.last_name}`}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          
                          {report.formattedDate}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{report.employee_id}</StyledTableCell>
                      {/* <StyledTableCell>{report.activity_name}</StyledTableCell> */}
                      <StyledTableCell>
                        <Box
                          sx={{
                            backgroundColor: 'secondary.main',
                            px: 2,
                            py: 1,
                            borderRadius: 1,
                            display: 'inline-block',
                            color: 'white',
                            fontWeight: 500
                          }}
                        >
                          {`${report.hours_worked} hrs`}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <StyledButton
                          component={Link}
                          to="/time-report"
                          state={{ userId: report.user_id }}
                          variant="contained"
                          size="medium"
                          startIcon={<VisibilityIcon />}
                          sx={{ 
                            minWidth: 120,
                            px: 2
                          }}
                        >
                          View
                        </StyledButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Loading State */}
            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress sx={{ color: 'secondary.main' }} />
              </Box>
            )}

            {/* Empty State */}
            {!loading && reports.length === 0 && (
              <Box sx={{ 
                textAlign: 'center', 
                py: 6,
                px: 2,
                bgcolor: 'grey.50',
                borderRadius: 2
              }}>
                <AssignmentIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                <Typography variant="h6" sx={{ color: 'primary.main', mb: 1 }}>
                  No Time Reports Found
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary', mb: 3 }}>
                  There are currently no time reports to display.
                </Typography>
                <StyledButton
                  variant="contained"
                  onClick={fetchReports}
                  startIcon={<RefreshIcon />}
                >
                  Refresh Data
                </StyledButton>
              </Box>
            )}

            {/* Snackbar Notifications */}
            <Snackbar
              open={snackbar.open}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert 
                onClose={handleSnackbarClose} 
                severity={snackbar.severity}
                variant="filled"
                sx={{ 
                  width: '100%',
                  ...(snackbar.severity === 'success' && {
                    bgcolor: 'success'
                  })
                }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default TimeReportTable;