import React, { useState, useEffect } from 'react';
import {  useLocation } from 'react-router-dom';
import { red } from '@mui/material/colors';
import {
  Box,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  styled,
  createTheme,
  ThemeProvider,
  Snackbar
} from '@mui/material';
import {
  AccessTime as AccessTimeIcon,
  Refresh as RefreshIcon,
  Assignment as AssignmentIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import axios from 'axios';

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#004c97',
      light: '#3370ab',
      dark: '#003569',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff6c0e',
      light: '#ff8c3e',
      dark: '#b24b09',
      contrastText: '#ffffff',
    },
    error: {
        main: '#ef5350', // Red 500
        light: '#3370ab',
        dark: '#d32f2f', // Red 700
        contrastText: '#ffffff',
      },
  },
});

// Styled Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(3),
  fontSize: '1rem',
  '&.MuiTableCell-head': {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .MuiTableCell-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '&.MuiButton-containedSecondary': {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  '&.custom-red-button': {
    backgroundColor: theme.palette.error.main || '#d32f2f', // Red 500 fallback
    '&:checked': {
      backgroundColor:theme.palette.error.dark || '#d32f2f', // Red 700 for hover
    },
  },
}));


const Timesheetreport = () => {
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogAction, setDialogAction] = useState(null);
    const [actionIds, setActionIds] = useState([]);
    const [snackbar, setSnackbar] = useState(false);
  
    const location = useLocation();
    const formData = location.state || {};

    const handleOpenDialog = (action, ids) => {
        setDialogAction(action);
        setActionIds(ids);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogAction(null);
        setActionIds([]);
    };

    const handleApprove = async (ids) => {
        try {
            setLoading(true);
            for (const id of ids) {
                const approvalData = {
                    id: id,
                    user_id:formData.userId,
                    status: 10
                };
                await axios.patch("http://127.0.0.1:8000/approve-timesheet", approvalData, {  
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    withCredentials: true
                  });
            }
            setSnackbar({
                open: true,
                message: 'Approved Successfully',
                severity: 'success'
              });
            await fetchProjects();
            setSelectedRows([]);
        } catch (error) {
            // console.error("Approval failed:", error);
            setError("Failed to approve entries. Please try again.");
        } finally {
            setLoading(false);
            handleCloseDialog();
        }
    };

    const handleBulkApprove = () => {
        handleOpenDialog('approve', selectedRows);
    };

    const handleReject = async (ids) => {
        try {
            setLoading(true);
            // Implement your reject logic here
            console.log("Rejecting entries:", ids);
            await fetchProjects();
            setSelectedRows([]);
        } catch (error) {
            console.error("Rejection failed:", error);
            setError("Failed to reject entries. Please try again.");
        } finally {
            setLoading(false);
            handleCloseDialog();
        }
    };

    const fetchProjects = async () => {
        try {
          setLoading(true);
          setError(null);
          const user_id = { user_id: formData.userId };
          const response = await axios.post("http://127.0.0.1:8000/fetch-timesheet-report", user_id, {  
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          });
          const dataWithIds = response.data.map((row, index) => ({
            ...row,
            id: row.id || index,
            formattedDate: new Date(row.current_date).toLocaleDateString()
          }));
          setReport(dataWithIds);
        } catch (error) {
          setError("Failed to load timesheet data. Please try again.");
          console.error("Failed to fetch projects:", error);
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        fetchProjects();
      }, []);
    
   
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbar(false);
      };

    return (
        <ThemeProvider theme={theme}>
          <Box sx={{ p: 4, maxWidth: '1600px', mx: 'auto',mt:-11 }}>
          <Snackbar
              open={snackbar}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert 
                onClose={handleSnackbarClose} 
                severity="success" 
                sx={{ 
                  width: '100%',
                  bgcolor: 'success'
                }}
                elevation={6}
                variant="filled"
              >
                Approved ✅
              </Alert>
            </Snackbar>
            <Card elevation={3}>
              <CardContent sx={{ p: 4 }}>
                {/* Header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mb: 4, 
                      fontWeight: 600, 
                      color: 'primary.main',
                      borderBottom: '3px solid',
                      borderColor: 'secondary.main',
                      pb: 1
                    }}
                  >
                    <AssignmentIcon sx={{ mr: 1, verticalAlign: 'bottom' }} />
                    Timesheet Report
                  </Typography>
                  <Tooltip title="Refresh">
                    <IconButton 
                      onClick={fetchProjects}
                      sx={{ 
                        color: 'primary.main',
                        mb: 4,
                        '&:hover': {
                          color: 'secondary.main'
                        }
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
    
                {/* Bulk Action Buttons */}
                <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
                  <StyledButton
                    variant="contained"
                    startIcon={<CheckCircleIcon />}
                    disabled={selectedRows.length === 0}
                    onClick={handleBulkApprove}
                    color="success"
                  >
                    Approve Selected ({selectedRows.length})
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    startIcon={<CancelIcon />}
                    disabled={selectedRows.length === 0}
                    onClick={() => handleOpenDialog('reject', selectedRows)}
                    className="custom-red-button"
                  >
                    Reject Selected ({selectedRows.length})
                  </StyledButton>
                </Box>
    
                {/* Error Alert */}
                {error && (
                  <Alert severity="error" sx={{ mb: 4 }}>
                    {error}
                  </Alert>
                )}
    
                {/* Main Table */}
                <TableContainer component={Paper} sx={{ mb: 4, boxShadow: 3 }}>
                  <Table sx={{ minWidth: 1200 }}>
                    <TableHead>
                      <TableRow sx={{ bgcolor: 'primary.main' }}>
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            sx={{
                              color: 'white',
                              '&.Mui-checked': {
                                color: 'white',
                              },
                            }}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedRows(report.map(row => row.id));
                              } else {
                                setSelectedRows([]);
                              }
                            }}
                            checked={selectedRows.length === report.length && report.length > 0}
                            indeterminate={selectedRows.length > 0 && selectedRows.length < report.length}
                          />
                        </StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Project</StyledTableCell>
                        <StyledTableCell>Activity</StyledTableCell>
                        <StyledTableCell>Notes</StyledTableCell>
                        <StyledTableCell>Hours</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {report.map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              checked={selectedRows.includes(row.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedRows([...selectedRows, row.id]);
                                } else {
                                  setSelectedRows(selectedRows.filter(id => id !== row.id));
                                }
                              }}
                              sx={{
                                color: 'primary.main',
                                '&.Mui-checked': {
                                  color: 'secondary.main',
                                },
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={{ fontWeight: 500, color: 'primary.main' }}>
                            {`${row.first_name} ${row.last_name}`}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {row.current_date}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>{row.project_name}</StyledTableCell>
                          <StyledTableCell>{row.activity_name}</StyledTableCell>
                          <StyledTableCell>{row.notes}</StyledTableCell>
                          <StyledTableCell>
                            <Box
                              sx={{
                                backgroundColor: 'secondary.main',
                                px: 2,
                                py: 1,
                                borderRadius: 1,
                                display: 'inline-block',
                                color: 'white',
                                fontWeight: 500
                              }}
                            >
                              {`${row.hours_worked} hrs`}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack direction="row" spacing={1}>
                              <Tooltip title="Approve">
                                <IconButton
                                  size="small"
                                  onClick={() => handleOpenDialog('approve', [row.id])}
                                  sx={{
                                    color: 'secondary.main',
                                    '&:hover': {
                                      bgcolor: 'secondary.light + 20'
                                    }
                                  }}
                                >
                                  <CheckCircleIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Reject">
                                <IconButton
                                  size="small"
                                  onClick={() => handleOpenDialog('reject', [row.id])}
                                  sx={{
                                    color: 'primary.main',
                                    '&:hover': {
                                      bgcolor: 'primary.light + 20'
                                    }
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
    
                {/* Loading State */}
                {loading && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                    <CircularProgress sx={{ color: 'secondary.main' }} />
                  </Box>
                )}
    
                {/* Empty State */}
                {!loading && report.length === 0 && (
                  <Box sx={{ 
                    textAlign: 'center', 
                    py: 6,
                    px: 2,
                    bgcolor: 'grey.50',
                    borderRadius: 2
                  }}>
                    <AssignmentIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                    <Typography variant="h6" sx={{ color: 'primary.main', mb: 1 }}>
                      No Timesheet Entries
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary', mb: 3 }}>
                      There are no timesheet entries to display at the moment.
                    </Typography>
                    <StyledButton
                      variant="contained"
                      onClick={fetchProjects}
                      startIcon={<RefreshIcon />}
                      color="secondary"
                    >
                      Refresh Data
                    </StyledButton>
                  </Box>
                )}
    
                {/* Confirmation Dialog */}
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    sx: { 
                      borderRadius: 2,
                      borderTop: '4px solid',
                      borderColor: dialogAction === 'approve' ? 'secondary.main' : 'primary.main'
                    }
                  }}
                >
                  <DialogTitle>
                    {dialogAction === 'approve' ? 'Confirm Approval' : 'Confirm Rejection'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to {dialogAction === 'approve' ? 'approve' : 'reject'} the selected timesheet {actionIds.length > 1 ? 'entries' : 'entry'}?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ p: 2 }}>
                    <Button 
                      onClick={handleCloseDialog}
                      variant="outlined"
                      color="inherit"
                    >
                      Cancel
                    </Button>
                    <StyledButton
                      onClick={() => dialogAction === 'approve' ? handleApprove(actionIds) : handleReject(actionIds)}
                      variant="contained"
                      color={dialogAction === 'approve' ? 'secondary' : 'primary'}
                    >
                      {dialogAction === 'approve' ? 'Approve' : 'Reject'}
                    </StyledButton>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
          </Box>
        </ThemeProvider>
      );
    };
export default Timesheetreport;