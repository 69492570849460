import React, { useState, useEffect } from 'react';
import { useAuth } from 'context/AuthContext';
import {
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  Alert,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Send as SendIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiCardContent-root': {
    padding: theme.spacing(2),
  }
}));

const TaskRow = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 2fr auto',
    gap: theme.spacing(2),
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  }));

const Timesheet = () => {
    const { user } = useAuth();
    const [projects, setProjects] = useState([]);                                                     
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [totalHours, setTotalHours] = useState(0);
    const [savedEntries, setSavedEntries] = useState([]); // New state for saved entries


    const [entries, setEntries] = useState([{
        id: Date.now(),
        date: '',
        projects: [{
            id: Date.now() + 1,
            name: '',
            activityId: '',
            tasks: [{
                id: Date.now() + 2,
                hours: '',
                notes: '',
                taskActivityId: '' 
            }]
        }]
    }]);

    
    const isWeekend = () => {
        const today = new Date();
        const day = today.getDay(); 
        return day === 2 || day === 5
        ;
    };
    
    const fetchTimesheet = async () => {
        try {
            
            console.log(user);
            
            setIsLoading(true);
            const response = await axios.post("http://127.0.0.1:8000/fetch-timesheet", user,{  
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true
              });
            setSavedEntries(response.data); 
            
            if (response.data && response.data.length > 0) {
                
                const groupedByDate = response.data.reduce((acc, entry) => {
                    const date = entry.current_date;
                    if (!acc[date]) {
                        acc[date] = {
                            projects: {}
                        };
                    }
                    
                    const projectId = entry.project_id;
                    if (!acc[date].projects[projectId]) {
                        acc[date].projects[projectId] = {
                            activityId: entry.activity_id,
                            tasks: []
                        };
                    }
                    
                    acc[date].projects[projectId].tasks.push({
                        id: entry.id,
                        hours: entry.hours_worked,
                        notes: entry.notes || '',
                        taskActivityId: entry.activity_id 
                    });
                    
                    return acc;
                }, {});

                // Convert the grouped data to the entries format
                const formattedEntries = Object.entries(groupedByDate).map(([date, dateData]) => ({
                    id: Date.now() + Math.random(),
                    date: date,
                    projects: Object.entries(dateData.projects).map(([projectId, projectData]) => ({
                        id: Date.now() + Math.random(),
                        name: projectId,
                        activityId: projectData.activityId,
                        tasks: projectData.tasks
                    }))
                }));

                setEntries(formattedEntries);
                
                // Fetch activities for each project
                const uniqueProjectIds = [...new Set(response.data.map(entry => entry.project_id))];
                for (const projectId of uniqueProjectIds) {
                    await fetchActivity(projectId);
                }
            }
        } catch (error) {
           console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTimesheet();
    }, []);

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        try {
          
            const response = await axios.post("http://127.0.0.1:8000/fetch-projects",user,{  
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true
              }
            );

            setProjects(response.data);
        } catch (error) {
            // More specific error handling
            if (axios.isAxiosError(error)) {
              if (error.response?.status === 401) {
                setError({ 
                  severity: 'error', 
                  message: "Authentication failed. Please login again." 
                });
              } else if (error.code === 'ECONNABORTED') {
                setError({ 
                  severity: 'error', 
                  message: "Request timed out. Please try again." 
                });
              } else {
                setError({ 
                  severity: 'error', 
                  message: "Failed to load projects" 
                });
              }
            }
            console.error("Failed to fetch projects:", error);
          }
    };

    const fetchActivity = async(projectId) => {
        setIsLoading(true);
        try {
            const response = await axios.post("http://127.0.0.1:8000/fetch-activity",{
                id: projectId,
                user_id:user.user_id
            },{  
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true
              });
            setActivities(response.data);
        } catch (error) {
            console.error("Failed to fetch activity:", error);
            setError({ severity: 'error', message: "Failed to load activity" });
        } finally {
            setIsLoading(false);
        }
    };

    const addEntry = () => {
        setEntries([
            ...entries,
            {
                id: Date.now(),
                date: '',
                projects: [{
                    id: Date.now() + 1,
                    name: '',
                    activityId: '',
                    tasks: [{
                        id: Date.now() + 2,
                        hours: '',
                        notes: ''
                    }]
                }]
            }
        ]);
    };

    const addProject = (entryId) => {
        setEntries(entries.map(entry => {
            if (entry.id === entryId) {
                return {
                    ...entry,
                    projects: [
                        ...entry.projects,
                        {
                            id: Date.now(),
                            name: '',
                            activityId: '',
                            tasks: [{
                                id: Date.now() + 1,
                                hours: '',
                                notes: ''
                            }]
                        }
                    ]
                };
            }
            return entry;
        }));
    };
    const addTask = (entryId, projectId) => {
        setEntries(entries.map(entry => {
            if (entry.id === entryId) {
                return {
                    ...entry,
                    projects: entry.projects.map(project => {
                        if (project.id === projectId) {
                            return {
                                ...project,
                                tasks: [
                                    ...project.tasks,
                                    {
                                        id: Date.now(),
                                        hours: '',
                                        notes: '',
                                        taskActivityId: project.activityId || '' 
                                    }
                                ]
                            };
                        }
                        return project;
                    })
                };
            }
            return entry;
        }));
    };

    const handleChange = (entryId, projectId, taskId, field, value) => {
        setEntries(entries.map(entry => {
            if (entry.id === entryId) {
                if (field === 'date') {
                    return { ...entry, date: value };
                }
                return {
                    ...entry,
                    projects: entry.projects.map(project => {
                        if (project.id === projectId) {
                            if (field === 'projectName') {
                                fetchActivity(value);
                                return { 
                                    ...project, 
                                    name: value, 
                                    activityId: '',
                                    tasks: project.tasks.map(task => ({
                                        ...task,
                                        taskActivityId: '' 
                                    }))
                                };
                            }
                            if (field === 'activityId') {
                                return { 
                                    ...project, 
                                    activityId: value,
                                    tasks: project.tasks.map(task => ({
                                        ...task,
                                        taskActivityId: value 
                                    }))
                                };
                            }
                            return {
                                ...project,
                                tasks: project.tasks.map(task => {
                                    if (task.id === taskId) {
                                        if (field === 'taskActivityId') {
                                            return { ...task, taskActivityId: value };
                                        }
                                        return { ...task, [field]: value };
                                    }
                                    return task;
                                })
                            };
                        }
                        return project;
                    })
                };
            }
            return entry;
        }));
    };

    const removeEntry = (entryId) => {
        setEntries(entries.filter(entry => entry.id !== entryId));
    };

    const removeProject = (entryId, projectId) => {
        setEntries(entries.map(entry => {
            if (entry.id === entryId) {
                return {
                    ...entry,
                    projects: entry.projects.filter(project => project.id !== projectId)
                };
            }
            return entry;
        }));
    };

    const removeTask = (entryId, projectId, taskId) => {
        setEntries(entries.map(entry => {
            if (entry.id === entryId) {
                return {
                    ...entry,
                    projects: entry.projects.map(project => {
                        if (project.id === projectId) {
                            return {
                                ...project,
                                tasks: project.tasks.filter(task => task.id !== taskId)
                            };
                        }
                        return project;
                    })
                };
            }
            return entry;
        }));
    };

    useEffect(() => {
        const total = entries.reduce((acc, entry) => {
            const entryTotal = entry.projects.reduce((projAcc, project) => {
                const projectTotal = project.tasks.reduce((taskAcc, task) => {
                    return taskAcc + (parseFloat(task.hours) || 0);
                }, 0);
                return projAcc + projectTotal;
            }, 0);
            return acc + entryTotal;
        }, 0);
        setTotalHours(total);
    }, [entries]);

    const handleSaveTimesheet = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const hasEmptyFields = entries.some(entry => 
                !entry.date || 
                entry.projects.some(project => 
                    !project.name || 
                    project.tasks.some(task => 
                        !task.hours || 
                        !task.taskActivityId 
                    )
                )
            );

            if (hasEmptyFields) {
                throw new Error('Please fill in all required fields before submitting.');
            }

            const timesheetData = entries.flatMap(entry => 
                entry.projects.flatMap(project => 
                    project.tasks.map(task => {
                        const savedEntry = savedEntries.find(saved => {
                          
                            
                            return saved.current_date === entry.date &&
                                   saved.project_id === parseInt(project.name) 
                        });
                       
                        return {
                            id: savedEntry?.id || null, // Use existing ID if available
                            current_date: entry.date,
                            notes: task.notes || '',
                            hours_worked: task.hours,
                            project_id: project.name,
                            activity_id: task.taskActivityId,
                            status: 8,
                            user_id:user.user_id
                        };
                    })
                )
            );
            console.log(timesheetData);
            
            

            for (const entry of timesheetData) {
                await axios.put('http://127.0.0.1:8000/timesheet', entry,{  
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    withCredentials: true
                  });
            }

            setError({ severity: 'success', message: 'Timesheet saved successfully!' });
             // Refresh the data
        } catch (error) {
            console.error('Error saving timesheet:', error);
            setError({ severity: 'error', message: error.message || 'Failed to save timesheet' });
        } finally {
            fetchTimesheet();
            setIsLoading(false);
        }
    };

    const handleSubmitTimesheet = async () => {
        if (!isWeekend()) {
            setError({ severity: 'error', message: 'Timesheet can only be submitted on Friday or Saturday' });
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const hasEmptyFields = entries.some(entry => 
                !entry.date || 
                entry.projects.some(project => 
                    !project.name || 
                    !project.activityId ||
                    project.tasks.some(task => 
                        !task.hours || 
                        !task.taskActivityId
                    )
                )
            );

            if (hasEmptyFields) {
                throw new Error('Please fill in all required fields before submitting.');
            }

            const timesheetData = entries.flatMap(entry => 
                entry.projects.flatMap(project => 
                    project.tasks.map(task => {
                        const savedEntry = savedEntries.find(saved => 
                            
                            saved.current_date === entry.date &&
                            saved.project_id === parseInt(project.name) 
                        );
                        console.log(savedEntry);
                        
    
                        // Only include entries that have a saved ID
                        if (savedEntry?.id) {
                            return {
                                id: savedEntry.id,
                                current_date: entry.date,
                                notes: task.notes || '',
                                hours_worked: task.hours,
                                project_id: project.name,
                                activity_id: task.taskActivityId,
                                status: 9,
                                user_id:user.user_id
                            };
                        }
                        return null;  
                    }).filter(Boolean)  
                )
            );
    
            // Check if there are any entries to submit
            if (timesheetData.length === 0) {
                throw new Error('No saved entries found to submit. Please save your entries first.');
            }
            console.log(timesheetData);
            

            for (const entry of timesheetData) {
                await axios.put('http://127.0.0.1:8000/timesheet', entry,{  
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    withCredentials: true
                  });
            }

            setEntries([{
                id: Date.now(),
                date: '',
                projects: [{
                    id: Date.now() + 1,
                    name: '',
                    activityId: '',
                    tasks: [{
                        id: Date.now() + 2,
                        hours: '',
                        notes: '',
                        taskActivityId: '' 
                    }]
                }]
            }]);

            setError({ severity: 'success', message: 'Timesheet submitted successfully!' });

        } catch (error) {
            console.error('Error submitting timesheet:', error);
            setError({ severity: 'error', message: error.message || 'Failed to submit timesheet' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
        
        <Box sx={{ maxWidth: 1200, margin: '2rem auto', p: 1 ,mt:-9}}>
            
        <Paper elevation={3} sx={{ p: 3 }}>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 5 }}>
                <Typography variant="h4" component="h1">
                    Weekly Timesheet
                </Typography>
                <Typography variant="h6">
                    Total Hours: <Box component="span" sx={{ color: 'primary.main' }}>{totalHours.toFixed(2)}</Box>
                </Typography>
            </Box>
            
            {error && (
                <Alert severity={error.severity || 'error'} sx={{ mb: 2 }}>
                    {error.message}
                </Alert>
            )}

            {entries.map(entry => (
                <StyledCard key={entry.id}>
                    <CardHeader
                        action={
                            <IconButton onClick={() => removeEntry(entry.id)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                        title={
                            <TextField
                                type="date"
                                value={entry.date}
                                onChange={(e) => handleChange(entry.id, null, null, 'date', e.target.value)}
                                required
                                fullWidth
                            />
                        }
                    />
                    <CardContent>
                        {entry.projects.map(project => (
                            <Box key={project.id} sx={{ mb: 3 }}>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={12} md={11.5}>
                                        <FormControl fullWidth>
                                            <InputLabel>Select Project</InputLabel>
                                            <Select
                                                value={project.name}
                                                onChange={(e) => handleChange(entry.id, project.id, null, 'projectName', e.target.value)}
                                                label="Select Project"
                                                required
                                                suppressHydrationWarning={true}
                                            >
                                                <MenuItem value="">Select Project</MenuItem>
                                                {projects.map(proj => (
                                                    <MenuItem key={proj.id} value={proj.id}>
                                                        {proj.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                  
                                    
                                        <IconButton 
                                            onClick={() => removeProject(entry.id, project.id)}
                                            color="error"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    
                                </Grid>

                                <Box sx={{ mb: 2 }}>
                                <TaskRow sx={{ fontWeight: 'bold' }}>
                                   
                                    <Typography>Activity</Typography>
                                    <Typography>Hours</Typography>
                                    <Typography>Notes</Typography>
                                    <Typography>Actions</Typography>
                                </TaskRow>
                                    <Divider />
                                    
                                {project.tasks.map(task => (
                                    <TaskRow key={task.id}>

                                        <FormControl   size="small" fullWidth>
                                            <InputLabel >Select Activity</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={task.taskActivityId || ''}
                                                onChange={(e) => handleChange(entry.id, project.id, task.id, 'taskActivityId', e.target.value)}
                                                required
                                                disabled={!project.name}
                                            >
                                                {/* <MenuItem value="">Select Activity</MenuItem> */}
                                                {activities.map(activity => (
                                                    <MenuItem key={activity.id} value={activity.id}>
                                                        {activity.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            type="number"
                                            value={task.hours}
                                            onChange={(e) => handleChange(entry.id, project.id, task.id, 'hours', e.target.value)}
                                            placeholder="0.00"
                                            inputProps={{ step: "0.25", min: "0", max: "24" }}
                                            required
                                            size="small"
                                        />
                                        <TextField
                                            value={task.notes}
                                            onChange={(e) => handleChange(entry.id, project.id, task.id, 'notes', e.target.value)}
                                            placeholder="Additional notes"
                                            size="small"
                                        />
                                        <IconButton
                                            onClick={() => removeTask(entry.id, project.id, task.id)}
                                            color="error"
                                            size="small"
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </TaskRow>
                                ))}
                                </Box>

                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={() => addTask(entry.id, project.id)}
                                    variant="outlined"
                                    size="small"
                                >
                                    Add Task
                                </Button>
                            </Box>
                        ))}

                        <Button
                            startIcon={<AddIcon />}
                            onClick={() => addProject(entry.id)}
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 2 }}
                        >
                            Add Project
                        </Button>
                    </CardContent>
                </StyledCard>
            ))}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, pt: 2, borderTop: 1, borderColor: 'divider' }}>
                <Button
                    startIcon={<AddIcon />}
                    onClick={addEntry}
                    variant="contained"
                    color="secondary"
                >
                    Add New Day
                </Button>
                <Box>
                    <Button
                        startIcon={<SaveIcon />}
                        onClick={handleSaveTimesheet}
                        variant="outlined"
                        sx={{ mr: 2 }}
                        disabled={isLoading}
                    >
                        Save
                    </Button>
                    <Tooltip title={!isWeekend() ? "Timesheet can only be submitted on Friday or Saturday" : ""}>
                        <span>
                            <Button
                                startIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
                                onClick={handleSubmitTimesheet}
                                variant="contained"
                                disabled={isLoading || !isWeekend()}
                            >
                                {isLoading ? 'Submitting...' : 'Submit Timesheet'}
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
        </Paper>
        
    </Box>
    </>
    );
};

export default Timesheet;