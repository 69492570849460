import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  Avatar,
  IconButton,
  Chip
} from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Link } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

const LeaveRequestsTable = () => {
  const { user } = useAuth();
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchLeaveRequests();
  }, []);

  const fetchLeaveRequests = async () => {
    try {
      const response = await axios.post('http://127.0.0.1:8000/get-leaverequests',
        {user_id:user.role_id},{
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
      setLeaveRequests(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: '40px auto', px: 4,mt:-10 }}>
      {/* Header */}
      <Paper elevation={0} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Box display="flex" alignItems="center" gap={2} mb={1}>
          <CalendarTodayIcon color="primary" sx={{ fontSize: 24 }} />
          <Typography variant="h5" fontWeight="500">
            Employee Leave Dashboard
          </Typography>
        </Box>
        <Typography color="text.secondary" variant="body2">
          Overview of employee leave requests and total days taken
        </Typography>
      </Paper>

      {/* Table */}
      <Paper elevation={0} sx={{ borderRadius: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ 
                  fontSize: '0.95rem', 
                  fontWeight: 500,
                  backgroundColor: '#f8f9fa',
                  py: 2
                }}>Employee</TableCell>
                <TableCell sx={{ 
                  fontSize: '0.95rem', 
                  fontWeight: 500,
                  backgroundColor: '#f8f9fa',
                  py: 2
                }}>ID</TableCell>
                <TableCell sx={{ 
                  fontSize: '0.95rem', 
                  fontWeight: 500,
                  backgroundColor: '#f8f9fa',
                  py: 2,
                  textAlign: 'center'
                }}>Total Days</TableCell>
                <TableCell sx={{ 
                  fontSize: '0.95rem', 
                  fontWeight: 500,
                  backgroundColor: '#f8f9fa',
                  py: 2,
                  textAlign: 'right'
                }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveRequests.map((request) => (
                <TableRow key={request.id} hover>
                  <TableCell sx={{ py: 2 }}>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar 
                        sx={{ 
                          bgcolor: 'primary.main',
                          width: 40,
                          height: 40,
                          fontSize: '1rem'
                        }}
                      >
                        {request.first_name[0]}
                      </Avatar>
                      <Typography>
                        {request.first_name} {request.last_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: 2 }}>
                    <Chip 
                      label={request.employee_id}
                      variant="outlined"
                      size="small"
                      sx={{ 
                        borderRadius: 1.5,
                        fontSize: '0.875rem',
                        height: 28
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ py: 2, textAlign: 'center' }}>
                    <Chip
                      label={`${request.total_days} days`}
                      color="primary"
                      size="small"
                      sx={{ 
                        borderRadius: 1.5,
                        fontSize: '0.875rem',
                        height: 28
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ py: 2, textAlign: 'right' }}>
                  <Link 
            to="/approve-leave"
            state={{ userId:request.id }}
            style={{ textDecoration: 'none' }}
          >
                    <IconButton 
                    
                    > 
                      <VisibilityIcon />
                    </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Details Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6">Leave Details</Typography>
        </DialogTitle>
        <DialogContent dividers>
          {selectedUser && (
            <Box sx={{ py: 1 }}>
              <Box mb={3}>
                <Box display="flex" alignItems="center" gap={2} mb={2}>
                  <Avatar 
                    sx={{ 
                      bgcolor: 'primary.main',
                      width: 48,
                      height: 48
                    }}
                  >
                    {selectedUser.first_name[0]}
                  </Avatar>
                  <Box>
                    <Typography variant="h6">
                      {selectedUser.first_name} {selectedUser.last_name}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {selectedUser.employee_id}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography color="text.secondary" variant="subtitle2" gutterBottom>
                    Leave Summary
                  </Typography>
                  <Chip
                    label={`Total: ${selectedUser.total_days} days`}
                    color="primary"
                    sx={{ mt: 1 }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            variant="contained"
            disableElevation
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LeaveRequestsTable;