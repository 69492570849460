import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from 'context/AuthContext';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Stack,
  Chip,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from '@mui/material';
import { format } from 'date-fns';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const API_BASE_URL = 'http://127.0.0.1:8000';

const LeaveApproval = () => {
  const { user } = useAuth();
  const location = useLocation();
  const userId = location.state?.userId;

  const [leaveRequests, setLeaveRequests] = useState([]);
  const [selectedLeaves, setSelectedLeaves] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processingIds, setProcessingIds] = useState([]);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const showNotification = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const fetchLeaveRequests = async () => {
    try {
      setError(null);
      const response = await axios.post(`${API_BASE_URL}/singe-user-leave`, { 
        id: userId 
      },{
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      setLeaveRequests(response.data);
    } catch (err) {
      let errorMessage = 'Failed to fetch leave requests';
      if (err.response?.data?.detail) {
        errorMessage = err.response.data.detail;
      } else if (err.response?.status === 500) {
        errorMessage = 'Internal server error occurred';
      }
      setError(errorMessage);
      showNotification(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchLeaveRequests();
    }
  }, [userId]);

  const handleApproveLeave = async (leaveIds) => {
    const idsToProcess = Array.isArray(leaveIds) ? leaveIds : [leaveIds];
    setProcessingIds(prev => [...prev, ...idsToProcess]);
    
    try {
      const failedApprovals = [];
      
      for (const leaveId of idsToProcess) {
        const leaveRequest = leaveRequests.find(req => req.id === leaveId);
        if (!leaveRequest) continue;
        
        try {
          await axios.patch(`${API_BASE_URL}/approve-leave`, {
            id: leaveId,
            employee_id: leaveRequest.employee_id,
            status: 10,
            approve_id: user.user_id
          },{
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          });
        } catch (err) {
          failedApprovals.push(leaveId);
          const errorMessage = err.response?.data?.detail || 'Failed to approve leave request';
          throw new Error(errorMessage);
        }
      }

      // Clear selections and dialog state after successful approval
      setSelectedLeaves([]);
      setSelectedRequest(null);
      setOpenDialog(false);
      
      // Show appropriate success message
      const message = idsToProcess.length > 1 
        ? 'All selected leave requests approved successfully'
        : 'Leave request approved successfully';
      showNotification(message);

      // Refresh the leave requests
      await fetchLeaveRequests();

    } catch (err) {
      let errorMessage = 'Failed to approve leave request';
      if (err.response?.data?.detail) {
        errorMessage = err.response.data.detail;
      } else if (err.response?.status === 500) {
        errorMessage = `Internal server error: ${err.response?.data?.detail || err.message}`;
      } else {
        errorMessage = err.message;
      }
      setError(errorMessage);
      showNotification(errorMessage, 'error');
    } finally {
      setProcessingIds(prev => prev.filter(id => !idsToProcess.includes(id)));
    }
  };

  const handleSelectAll = (event) => {
    setSelectedLeaves(event.target.checked ? leaveRequests.map(request => request.id) : []);
  };

  const handleSelect = (id) => {
    setSelectedLeaves(prev => 
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const isAllSelected = leaveRequests.length > 0 && 
    leaveRequests.every(request => selectedLeaves.includes(request.id));
  const isIndeterminate = leaveRequests.some(request => 
    selectedLeaves.includes(request.id)) && !isAllSelected;

  return (
    <Box sx={{ maxWidth: 1400, margin: '40px auto', px: 4, mt: -11 }}>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3, fontSize: '1.25rem' }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      <Paper elevation={0} sx={{ p: 4, mb: 3, borderRadius: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" sx={{ fontSize: '2rem', fontWeight: 600 }}>
            Leave Approval Dashboard
          </Typography>
          {selectedLeaves.length > 0 && (
            <Button
              variant="contained"
              startIcon={processingIds.length ? 
                <CircularProgress size={20} color="inherit" /> : 
                <DoneAllIcon />
              }
              onClick={() => handleApproveLeave(selectedLeaves)}
              disabled={processingIds.length > 0}
              sx={{ 
                borderRadius: 2, 
                fontSize: '1.25rem', 
                padding: '12px 24px',
                height: '50px'
              }}
            >
              {processingIds.length ? 
                `Processing (${selectedLeaves.length})...` : 
                `Approve Selected (${selectedLeaves.length})`
              }
            </Button>
          )}
        </Stack>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: 2 }}>
        <TableContainer>
          <Table size="large">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" sx={{ width: '60px' }}>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={isIndeterminate}
                    onChange={handleSelectAll}
                    disabled={processingIds.length > 0}
                    sx={{ 
                      '& .MuiSvgIcon-root': { 
                        fontSize: '1.75rem',
                        padding: '4px'
                      } 
                    }}
                  />
                </TableCell>
                {['Employee Code', 'Employee Name', 'Start Date', 'End Date', 'Duration', 'Submission Date', 'Actions']
                  .map((header, index) => (
                    <TableCell 
                      key={header}
                      align={index === 6 ? 'right' : 'left'}
                      sx={{ 
                        fontWeight: 600, 
                        fontSize: '1.35rem',
                        padding: '20px 16px',
                        backgroundColor: '#f8f9fa'
                      }}
                    >
                      {header}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveRequests.map((request) => (
                <TableRow 
                  key={request.id}
                  hover
                  selected={selectedLeaves.includes(request.id)}
                  sx={{ height: '80px' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedLeaves.includes(request.id)}
                      onChange={() => handleSelect(request.id)}
                      disabled={processingIds.includes(request.id)}
                      sx={{ 
                        '& .MuiSvgIcon-root': { 
                          fontSize: '1.75rem',
                          padding: '4px'
                        } 
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={request.employee_code}
                      variant="outlined"
                      color="primary"
                      sx={{ 
                        fontSize: '1.25rem', 
                        height: '40px',
                        '& .MuiChip-label': { 
                          padding: '0 16px',
                          lineHeight: '40px'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={`${request.first_name} ${request.last_name}`}
                      variant="outlined"
                      color="primary"
                      sx={{ 
                        fontSize: '1.25rem', 
                        height: '40px',
                        '& .MuiChip-label': { 
                          padding: '0 16px',
                          lineHeight: '40px'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ fontSize: '1.25rem' }}>
                    {format(new Date(request.leave_start_date), 'dd MMM yyyy')}
                  </TableCell>
                  <TableCell sx={{ fontSize: '1.25rem' }}>
                    {format(new Date(request.leave_end_date), 'dd MMM yyyy')}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={`${request.duration} days`}
                      color="primary"
                      sx={{ 
                        fontSize: '1.25rem', 
                        height: '40px',
                        '& .MuiChip-label': { 
                          padding: '0 16px',
                          lineHeight: '40px'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ fontSize: '1.25rem' }}>
                    {format(new Date(request.submission_date), 'dd MMM yyyy')}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      startIcon={processingIds.includes(request.id) ? 
                        <CircularProgress size={20} color="inherit" /> : 
                        <CheckCircleIcon />
                      }
                      onClick={() => {
                        setSelectedRequest(request);
                        setOpenDialog(true);
                      }}
                      disabled={processingIds.includes(request.id)}
                      sx={{ 
                        borderRadius: 2,
                        fontSize: '1.25rem',
                        padding: '10px 20px',
                        height: '45px'
                      }}
                    >
                      {processingIds.includes(request.id) ? 'Processing...' : 'Approve'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 500, p: 3 }}>
          Confirm Leave Approval
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Typography sx={{ fontSize: '1.25rem', mb: 3 }}>
            Are you sure you want to approve this leave request?
          </Typography>
          {selectedRequest && (
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: '1.25rem', color: 'text.secondary', mb: 2 }}>
                Employee: {selectedRequest.first_name} {selectedRequest.last_name}
              </Typography>
              <Typography sx={{ fontSize: '1.25rem', color: 'text.secondary', mb: 2 }}>
                Duration: {selectedRequest.duration} days
              </Typography>
              <Typography sx={{ fontSize: '1.25rem', color: 'text.secondary', mb: 2 }}>
                From: {format(new Date(selectedRequest.leave_start_date), 'dd MMM yyyy')}
              </Typography>
              <Typography sx={{ fontSize: '1.25rem', color: 'text.secondary' }}>
                To: {format(new Date(selectedRequest.leave_end_date), 'dd MMM yyyy')}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            sx={{ fontSize: '1.25rem' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleApproveLeave(selectedRequest.id)}
            disabled={processingIds.length > 0}
            sx={{ fontSize: '1.25rem' }}
          >
            {processingIds.length > 0 ? 'Processing...' : 'Approve'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LeaveApproval;