// context/AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { api } from '../utils/axiosConfig';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await api.post('/login', {
        email,
        password
      });
      
      setUser(data);
      return data;
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 'Login failed';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Projects API
  const addProject = async (projectData) => {
    try {
      const { data } = await api.post('/addproject', projectData);
      return data;
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 'Failed to add project';
      throw new Error(errorMessage);
    }
  };

  const AddActivity = async (activityData) => {
    try {
      const { data } = await api.post('/addactivity', activityData);
      return data;
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 'Failed to add activity';
      throw new Error(errorMessage);
    }
  };

  const fetchProject = async () => {
    try {
      const { data } = await api.get('/getprojects');
      return data;
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 'Failed to Load Projects';
      throw new Error(errorMessage);
    }
  };

  const fetch_Details = async () => {
    try {
      const response = await api.get('/project-assign-details');
      if (!response.data || !response.data.data) {
        throw new Error('Invalid response format');
      }
      return response.data;
    } catch (err) {
      console.error("Fetch details error:", err);
      const errorMessage = err.response?.data?.detail || 'Failed to Load Assign Details';
      throw new Error(errorMessage);
    }
  };

  const project_Assign = async (assignmentData) => {
    try {
      const { data } = await api.post('/projectassign', assignmentData);
      return data;
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 'Failed to assign project';
      throw new Error(errorMessage);
    }
  };

  const logout = async () => {
    try {
      const result =  await api.get('/log-out');
      setUser(null);
      return result
    } catch (err) {
      console.error('Logout failed', err);
    }
  };


  const value = {
    user,
    loading,
    error,
    login,
    addProject,
    AddActivity,
    fetchProject,
    fetch_Details,
    project_Assign,
    logout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};