import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Typography,
  Alert,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  styled,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  createTheme,
  ThemeProvider
} from '@mui/material';
import { Edit, Save, Cancel, ExpandMore } from '@mui/icons-material';

// Custom theme with your brand colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#004c97',
      light: '#3370ab',
      dark: '#003569',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff6c0e',
      light: '#ff8c3e',
      dark: '#b24b09',
      contrastText: '#ffffff',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(3),
  fontSize: '1rem',
  '&.MuiTableCell-head': {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '1.1rem',
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .MuiTableCell-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiAccordionSummary-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '&.MuiButton-containedSecondary': {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));


const ActivityList = () => {
  const [projectActivities, setProjectActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingActivity, setEditingActivity] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editedValues, setEditedValues] = useState({
    activity_name: '',
    status: '',
    code: ''
  });

  const fetchActivities = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get('http://127.0.0.1:8000/fetchActivity', {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      setProjectActivities(response.data);
    } catch (err) {
      setError('Failed to fetch activities. Please try again later.');
      console.error('Error fetching activities:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  const handleEditClick = (activity) => {
    setEditingActivity(activity);
    setEditedValues({
      activity_name: activity.activity_name || '',
      status: activity.status || '',
      code: activity.code || ''
    });
    setDialogOpen(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setError(null);

      const updateData = {
        id: editingActivity.id,
        ...editedValues
      };

      // Assuming you have an endpoint to update activities
      const response = await axios.patch(
        'http://127.0.0.1:8000/updateactivity',
        updateData
      );

      if (response.data.message) {
        await fetchActivities();
        setDialogOpen(false);
        setEditingActivity(null);
        setSnackbarOpen(true);
      }
    } catch (err) {
      console.error('Error updating activity:', err.response?.data || err.message);
      setError(err.response?.data?.detail || 'Failed to update activity. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setEditingActivity(null);
    setEditedValues({
      activity_name: '',
      status: '',
      code: ''
    });
    setError(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const getStatusColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'planning':
        return theme.palette.info.light;
      case 'inprogress':
        return theme.palette.secondary.main; // Using orange for in-progress
      case 'completed':
        return theme.palette.success.main;
      case 'on hold':
        return theme.palette.error.main;
      default:
        return theme.palette.grey[300];
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 4, maxWidth: '1600px', mx: 'auto',mt:-8 }}>
        <Card elevation={3}>
          <CardContent sx={{ p: 4 }}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert 
                onClose={handleSnackbarClose} 
                severity="success" 
                sx={{ width: '100%', bgcolor: 'secondary.main' }}
                elevation={6}
                variant="filled"
              >
                Activity updated successfully!
              </Alert>
            </Snackbar>

            <Typography 
              variant="h4" 
              sx={{ 
                mb: 4, 
                fontWeight: 600, 
                color: 'primary.main',
                borderBottom: '3px solid',
                borderColor: 'secondary.main',
                pb: 1
              }}
            >
              Activity Management
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 4 }}>
                {error}
              </Alert>
            )}

            {projectActivities.map((project) => (
              <StyledAccordion key={project.project_name}>
                <AccordionSummary expandIcon={<ExpandMore sx={{ color: 'white' }} />}>
                  <Typography variant="h6">{project.project_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Activity Name</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                          <StyledTableCell>Code</StyledTableCell>
                          <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {project.activities.map((activity) => (
                          <StyledTableRow key={activity.id}>
                            <StyledTableCell>{activity.activity_name}</StyledTableCell>
                            <StyledTableCell>
                              <Box
                                sx={{
                                  backgroundColor: getStatusColor(activity.status),
                                  px: 2,
                                  py: 1,
                                  borderRadius: 1,
                                  display: 'inline-block',
                                  color: 'white'
                                }}
                              >
                                {activity.status}
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell>{activity.code}</StyledTableCell>
                            <StyledTableCell>
                              <StyledButton
                                startIcon={<Edit />}
                                onClick={() => handleEditClick(activity)}
                                variant="contained"
                                color="secondary"
                                size="large"
                                sx={{ px: 3 }}
                              >
                                Edit
                              </StyledButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </StyledAccordion>
            ))}

            <Dialog 
              open={dialogOpen} 
              onClose={handleClose} 
              maxWidth="md" 
              fullWidth
              PaperProps={{
                sx: { p: 2 }
              }}
            >
              <DialogTitle 
                sx={{ 
                  fontSize: '1.5rem', 
                  fontWeight: 600,
                  color: 'primary.main'
                }}
              >
                Edit Activity
              </DialogTitle>
              <DialogContent>
                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <TextField
                    label="Activity Name"
                    value={editedValues.activity_name}
                    onChange={(e) => setEditedValues({ ...editedValues, activity_name: e.target.value })}
                    fullWidth
                    size="large"
                  />
                  <FormControl fullWidth size="large">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={editedValues.status}
                      onChange={(e) => setEditedValues({ ...editedValues, status: e.target.value })}
                      label="Status"
                    >
                      <MenuItem value="Planning">Planning</MenuItem>
                      <MenuItem value="InProgress">In Progress</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                      <MenuItem value="OnHold">On Hold</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Code"
                    value={editedValues.code}
                    onChange={(e) => setEditedValues({ ...editedValues, code: e.target.value })}
                    fullWidth
                    size="large"
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <StyledButton 
                  onClick={handleClose}
                  startIcon={<Cancel />}
                  color="error"
                  variant="outlined"
                  size="large"
                  sx={{ px: 4 }}
                >
                  Cancel
                </StyledButton>
                <StyledButton 
                  onClick={handleSave}
                  startIcon={<Save />}
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                  size="large"
                  sx={{ px: 4 }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save'}
                </StyledButton>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
};

export default ActivityList;