import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Alert,
  Card,
  CardContent,
  CircularProgress,
  styled,
  Snackbar,
  createTheme,
  ThemeProvider,
  Chip,
  Avatar,
  IconButton,
  Dialog,
  DialogContent
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004c97',
      light: '#3370ab',
      dark: '#003569',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff6c0e',
      light: '#ff8c3e',
      dark: '#b24b09',
      contrastText: '#ffffff',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(3),
  fontSize: '1rem',
  '&.MuiTableCell-head': {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .MuiTableCell-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 50,
  height: 50,
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
}));

const ImagePreview = styled('img')({
  maxWidth: '100%',
  maxHeight: '80vh',
  objectFit: 'contain',
});

const AttendanceList = () => {
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  const fetchAttendance = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get('http://127.0.0.1:8000/attendance-details', {
        headers: {
          'Content-Type': 'application/json'
        }, withCredentials: true
      });
      setAttendance(response.data);
      setSnackbarOpen(true);
    } catch (err) {
      setError('Failed to fetch attendance data. Please try again later.');
      console.error('Error fetching attendance:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAttendance();
    // Set up auto-refresh every 5 minutes
    const interval = setInterval(fetchAttendance, 300000);
    return () => clearInterval(interval);
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const getImageUrl = (imagePath) => {
    if (!imagePath || imagePath === 'string') return null;
    return `http://127.0.0.1:8000/${imagePath}`;
  };

  const handleImageClick = (imagePath) => {
    const fullImageUrl = getImageUrl(imagePath);
    if (fullImageUrl) {
      setSelectedImage(fullImageUrl);
      setImageDialogOpen(true);
    }
  };

  const handleCloseImageDialog = () => {
    setImageDialogOpen(false);
    setSelectedImage(null);
  };

  const getStatusColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'present':
        return 'success';
      case 'absent':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    try {
      const [hours, minutes, seconds] = timeString.split(':');
      return `${hours}:${minutes}`;
    } catch (err) {
      return timeString;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 4, maxWidth: '1600px', mx: 'auto' }}>
        <Card elevation={3}>
          <CardContent sx={{ p: 4 }}>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert 
                onClose={handleSnackbarClose} 
                severity="success" 
                sx={{ width: '100%', bgcolor: 'secondary.main' }}
                elevation={6}
                variant="filled"
              >
                Attendance data fetched successfully!
              </Alert>
            </Snackbar>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 600, 
                  color: 'primary.main',
                  borderBottom: '3px solid',
                  borderColor: 'secondary.main',
                  pb: 1
                }}
              >
                Daily Attendance
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccessTimeIcon color="primary" />
                <Typography variant="h6" color="primary.main">
                  {new Date().toLocaleDateString()}
                </Typography>
              </Box>
            </Box>

            {error && (
              <Alert severity="error" sx={{ mb: 4 }}>
                {error}
              </Alert>
            )}

            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                <CircularProgress size={40} />
              </Box>
            ) : (
              <TableContainer component={Paper} sx={{ mb: 4, boxShadow: 3 }}>
                <Table sx={{ minWidth: 1200 }}>
                  <TableHead>
                    <TableRow sx={{ bgcolor: 'primary.main' }}>
                      <StyledTableCell>Photo</StyledTableCell>
                      <StyledTableCell>Employee Name</StyledTableCell>
                      <StyledTableCell>Employee Code</StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Time</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attendance.map((record, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <StyledAvatar
                              src={getImageUrl(record.user_image)}
                              alt={`${record.first_name} ${record.last_name}`}
                              onClick={() => record.user_image && record.user_image !== 'string' && 
                                handleImageClick(record.user_image)}
                            >
                              {record.first_name?.[0]}
                            </StyledAvatar>
                            <IconButton 
                              size="small" 
                              onClick={() => record.user_image && record.user_image !== 'string' && 
                                handleImageClick(record.user_image)}
                              disabled={!record.user_image || record.user_image === 'string'}
                            >
                              <ZoomInIcon />
                            </IconButton>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 500, color: 'primary.main' }}>
                          {`${record.first_name} ${record.last_name}`}
                        </StyledTableCell>
                        <StyledTableCell>{record.employee_code}</StyledTableCell>
                        <StyledTableCell>{record.marked_date || 'N/A'}</StyledTableCell>
                        <StyledTableCell>{formatTime(record.marked_time) || 'N/A'}</StyledTableCell>
                        <StyledTableCell>
                          <Chip
                            label={record.status}
                            color={getStatusColor(record.status)}
                            sx={{ fontWeight: 'bold', minWidth: 100 }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>

        <Dialog 
          open={imageDialogOpen} 
          onClose={handleCloseImageDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogContent sx={{ p: 0, position: 'relative', bgcolor: 'black' }}>
            <IconButton
              onClick={handleCloseImageDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            {selectedImage && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                <ImagePreview
                  src={selectedImage}
                  alt="Attendance Photo"
                />
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default AttendanceList;