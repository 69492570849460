import React, { useState ,useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { api } from '../utils/axiosConfig';
import axios from 'axios';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Fade,
  Menu,
  MenuItem,
  Badge,
  Stack,
  Tooltip,
  Collapse,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard,
  People,
  Assignment,
  Analytics,
  Settings,
  Notifications,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  AccessTime
} from '@mui/icons-material';

const Navbar = () => {
  const { user,logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState('');
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.post('http://127.0.0.1:8000/profile-fetch', {
          id: user.user_id
        },{
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
        setProfileData(response.data[0]); 
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    if (user?.user_id) {
      fetchProfile();
    }
  }, [user]);


  // Define menu items based on role
  const adminMenuItems = [
    { 
      title: 'Dashboard', 
      icon: <Dashboard sx={{ color: '#ff6c0e' }} />,
      path: '/home'
    },
    { 
      title: 'Employee Management', 
      icon: <People sx={{ color: '#ff6c0e' }} />,
      path :'/Employeemenu'
    },
    { 
      title: 'Timesheet Management', 
      icon: <AccessTime sx={{  color: '#ff6c0e' }} />,
      path: '/timesheetMenu'
    },
    // { 
    //   title: 'Reports', 
    //   icon: <Analytics sx={{ color: '#ff6c0e' }} />,
    //   path: '/time-list'
    // },
    { 
      title: 'Attendance Management', 
      icon: <Assignment sx={{ color: '#ff6c0e' }} />,
      path:'/Leavemenu'
     
    },
  ];


  const managerMenuItems = [
    { 
      title: 'Dashboard', 
      icon: <Dashboard sx={{ color: '#ff6c0e' }} />,
      path: '/home'
    },
    { 
      title: 'Timesheet', 
      icon: <Assignment sx={{ color: '#ff6c0e' }} />,
      path: '/time'
    },
    { 
      title: 'Reports', 
      icon: <Analytics sx={{ color: '#ff6c0e' }} />,
      path: '/time-list'
    },
  ];

  const menuItems = profileData?.role_id === 1 ? adminMenuItems : managerMenuItems;

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (path) => {
    if (path) {
      navigate(path);
    }
  };

  const handleSubMenuClick = (title) => {
    setOpenSubMenu(openSubMenu === title ? '' : title);
  };

  const handleLogout = async () => {
    try {
      const response =  await logout(); 
      // alert(response)
      if (!response){
        // alert(response)
      }
    else {   
    navigate('/'); 
    handleProfileClose();}
    } catch (err) {
      alert('Logout failed'); 
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
    <AppBar 
      position="fixed" 
      sx={{ 
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: '#004c97',
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          sx={{ mr: 2, color: 'white' }}
        >
          <MenuIcon />
        </IconButton>

        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600,
            color: 'white'
          }}
        >
          {profileData ? `Welcome, ${profileData.first_name} ${profileData.last_name}` : 'DASHBOARD'}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={1} alignItems="center">
          {/* <Tooltip title="Notifications">
            <IconButton sx={{ color: 'white' }}>
              <Badge badgeContent={4} color="error">
                <Notifications />
              </Badge>
            </IconButton>
          </Tooltip> */}

          <Tooltip title={profileData ? `${profileData.first_name} ${profileData.last_name}` : 'Profile'}>
            <IconButton onClick={handleProfileClick}>
              <Avatar 
                sx={{ 
                  width: 35, 
                  height: 35,
                  bgcolor: '#ff6c0e'
                }}
              >
                {profileData?.first_name?.[0]}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Stack>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleProfileClose}
          TransitionComponent={Fade}
        >
          {/* <MenuItem>
            <ListItemIcon>
              <Settings sx={{ color: '#ff6c0e' }} />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          {/* <MenuItem>
            <ListItemIcon>
              <Assignment sx={{ color: '#ff6c0e' }} />
            </ListItemIcon>
            Leave Balance: {profileData?.leave || 0}
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToApp sx={{ color: '#ff6c0e' }} />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>

    <Drawer
      variant="persistent"
      open={isDrawerOpen}
      sx={{
        width: 280,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 280,
          boxSizing: 'border-box',
          bgcolor: '#004c97',
          color: 'white',
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto', mt: 2 }}>
        <List>
          {menuItems?.map((item) => (
            <React.Fragment key={item.title}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={
                    item.path === location.pathname ||
                    (item.subItems?.some(sub => sub.path === location.pathname))
                  }
                  onClick={() => 
                    item.subItems 
                      ? handleSubMenuClick(item.title)
                      : handleMenuClick(item.path)
                  }
                  sx={{
                    mx: 2,
                    borderRadius: 2,
                    '&.Mui-selected': {
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.2)',
                      },
                    },
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 40, color: 'inherit' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                  {item.subItems && (
                    openSubMenu === item.title ? <ExpandLess /> : <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              {item.subItems && (
                <Collapse in={openSubMenu === item.title} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItemButton
                        key={subItem.title}
                        selected={location.pathname === subItem.path}
                        onClick={() => handleMenuClick(subItem.path)}
                        sx={{
                          pl: 4,
                          mx: 2,
                          borderRadius: 2,
                          '&.Mui-selected': {
                            bgcolor: 'rgba(255, 255, 255, 0.1)',
                            '&:hover': {
                              bgcolor: 'rgba(255, 255, 255, 0.2)',
                            },
                          },
                          '&:hover': {
                            bgcolor: 'rgba(255, 255, 255, 0.1)',
                          },
                        }}
                      >
                        <ListItemText primary={subItem.title} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Drawer>

    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      {/* Your main content goes here */}
    </Box>
  </Box>
  );
};

export default Navbar;